import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import PriceUpload from './components/PriceUpload';
import SubUpload from './components/SubUpload';
import PriceUpdate from './components/PriceUpdate';
import SubUpdate from './components/SubUpdate';
import Home from './components/Home';
import Login from './components/Login';
import ContactUs from './components/ContactUs';
import AMP from './components/AMP';
import Table from './components/Table';
import About from './components/About';
import Start from './components/Start';
import Support from './components/Support';
import Signup from './components/Signup';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Customers from './components/Customers';
import HomeMob from './components/HomeMob';
import NavbarMob from './components/NavbarMob';
import TablesMob from './components/TablesMob';
import ProductDetailPage from './components/ProductDetailPage';


function App() {

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (


    <div className="App">
      <BrowserRouter>
        <Routes>

          <Route path='/priceupload' element={<PriceUpload />} />
          <Route path='/subupload' element={<SubUpload />} />
          <Route path='/priceupdate' element={<PriceUpdate />} />
          <Route path='/subupdate' element={<SubUpdate />} />
          {isMobileView ? (
            <Route path='/' element={<HomeMob />} />
          ) : (
            <Route path='/' element={<Home />} />
          )}
          <Route path='/login' element={<Login />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/amp' element={<AMP />} />

          {isMobileView ? (
            <Route path='/table' element={<TablesMob />} />
          ) : (
            <Route path='/table' element={<Table />} />
          )}
          <Route path="/product" element={<ProductDetailPage />} />
          <Route path='/about' element={<About />} />
          <Route path='/start' element={<Start />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/customers' element={<Customers />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}




export default App;
