import React,{useEffect,useState} from 'react'
import './Privacy.css'
import Navbar from './Navbar'
import facebook from '../images/facebook.png'
import instagram from '../images/insta.png'
import mail from '../images/mail.png'
import Footer from './Footer'
import NavbarMob from './NavbarMob';
import FooterMob from './FooterMob'

function Privacy() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
      // Update the screen size on resize
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };

      // Attach event listener
      window.addEventListener('resize', handleResize);

      // Clean up the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='mainDiv'>
          <div className='Navbar'>
        {isMobile ? <NavbarMob /> : <Navbar />}
      </div> 
      <div className="privacy_root">
    <div className="privacyContent">
        <br />
      <div className="privacy_title">Privacy Policy</div>
      <br></br>
      <section>
        We, Fazco Auto Parts Trading, are committed to protecting the privacy
        of our visitors, for we do not spam, sell, rent, lease or give away
        the information to any third party/parties. <br></br>
        <br></br>It is projected to describe how we use and process your
        personal information. Please ensure that by visiting and using
        fazcoautoparts.com, you are agreeing and accepting the policies
        that are described in this Privacy Policy. <br />
        <br /> This Privacy Policy also explains how we are going to use your
        personal information and what happens to any personal data that you
        provide to us. Moreover, we do update the policies of this page from
        time to time, so please keep reviewing the page on regular basis.
      </section>
      <br />

      <label>1. Information We Collect</label>
      <br />
      <br />
      <section>
        (i) Personal Information
        <br />
        <br />
        The personal information that we may collect include name, contact
        information, IP addresses, product and service selections and other
        information, identifying you. We may also gather and collect personal
        information from you at different points, including but not limited to
        the following:
        <br /> <br />
        <li>
          At the time of communicating with you as a customer or prospective
          customer;
        </li>
        <li>
          {" "}
          When you prefer to visit our website or make use of the platform;
        </li>
        <li>
          While registering you as an end-user of our products or services and
          one account is especially made for you; When you fill our “Contact
          Us” form to get in touch with our experts;
        </li>{" "}
        <br />
        (ii) Non-personal Data <br />
        <br />
        We may also collect or gather non-personal data or information about
        users whenever they prefer to interact with our website. Non-personal
        data and information include the browser name that user access, the
        type of computer, technical information like operating system and
        Internet service providers and other similar information via Google
        Analytics.
      </section>
      <br />
      <label>2. Cookies</label>
      <br />
      <br />
      <section>
        <br />
        Our website does not collect any information about users, except for
        the information contained in Cookies. Talking about cookies, they are
        small text files, which are mainly saved and retrieved information
        about your visit to our website. We use cookies to give personalized
        experienced on our website. Here, you can find how we use cookies:{" "}
        <br />
        <br />
        <li>
          Session cookies: generally, session cookies are cached mainly for
          the duration of your use of our website;
        </li>
        <li>
          Permanent cookies: permanent cookies are stored in your terminal
          device for the time specified in the Cookie files parameters or
          until you delete.
        </li>{" "}
        <br /> You can adjust the settings on your computer in order to
        decline any cookies if you wish to.
      </section>
      <br />
      <label>3. Disclosing Your Information</label>
      <br />
      <br />
      <section>
        We assure you that we will not disclose your gathered personal
        information to any other party than in agreement with this Privacy
        Policy. Some of the circumstances given below when we may need to
        share your share: <br />
        <br />
        <li>
          In any situation, when we might have to sell any or all our business
          to the buyer.
        </li>
        <li>
          We may disclose your personal information when it is legally
          required by the law.
        </li>
        <li>To protect fraud and reduce the risk of fraud.</li>
        <br />
        FAZCO AUTOPARTS never sell, trade or rent users’ personal information to
        others. If required, we may share general demographic data and
        information that is not linked to any personal information regarding
        visitors with our trusted business partners, affiliates and
        advertisers for the purpose mentioned above.
      </section>
      <br />
      <label>4. Security</label>
      <br />
      <br />
      <section>
        Here, we make sure that your shared personal or non-personal
        information is safe and secure from any unauthorized access, use, loss
        or destruction. Personal or non-personal information that provided by
        you is stored on a safe and secured web server that is managed by
        Space-O Technologies. To protect from authorized access, we have used
        physical, electronic, and managerial procedures to safeguard and
        secure the information.
      </section>
      <br />
      <label>5. Third Party Links/Websites</label>
      <br />
      <br />
      <section>
        FAZCO AUTOPARTS may link to third-party websites and
        services when required; however, we are not responsible for privacy
        statements, practices or the contents of such third-party sites.
      </section>
      <br />
      <label>6. Changes to Privacy Policy</label>
      <br />
      <br />
      <section>
        FAZCO AUTOPARTS reserves the right to change, edit or amend
        this Privacy Policy at any time without any prior notice. When we
        change, edit or remove any part from this privacy policy, we will
        mention the updated date at the bottom of this privacy policy page. We
        encourage our users to keep reviewing this page frequently in order to
        stay updated with the changes that we perform. The continuing use of
        our website means that you agree and accept those changes.
      </section>
      <br />
      <label>7. Contact Us</label>
      <br />
      <br />
      <section>
        In case, if you have any query or question regarding our Privacy
        Policy, you can get in touch with us using Contact us form.
      </section>
      <br />
      <label> Contact Address</label>
      <br />
      <br />
      <section>
      WADI AL NEEL AUTO SPARE PARTS TRADING .LLC
        <br />
        Authorised Distributer Fazco Auto Parts<br /> UAE,Ajman.Ind,2 <br />
        Email:<span>&nbsp;fazcoautoparts@gmail.com</span>
      </section>
      <br />
      <br />
    </div>
    <div className="privacyContentFooter">
      <div className="privacyContentFooterdiv">
        <div className="privacyContentFooterdivpart">
          <label className="HaveQues">Have Questions?</label>
          <label className="getintouch">Get in touch with us</label>
          <label className="mobilenum"> 00971- 54 347 64 17</label>
        </div>
        <div className="divseperation"></div>
        <div className="privacyContentFooterdivpart">
          <label className="privacyEmail">Email</label>
          <label className="privacyEmailaddress">
           fazcoautoparts@gmail.com
          </label>
        </div>
        <div className="divseperation"></div>

        <div className="privacyContentFooterdivpart">
          <label className="privacyfollowus">Follow Us</label>
          <div className="socialiconsdiv">
            <div className="socialiconspart">
              <div className="socialicons">
                <img src={facebook} alt="fb" />
              </div>
            </div>
            <div className="socialiconspart">
              <div className="socialicons">
                <img src={instagram} alt="X" />
              </div>
            </div>
            <div className="socialiconspart">
              <div className="socialicons">
                <img src={mail} alt="in" />
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
    {isMobile ? <FooterMob /> : <Footer />}

  </div>

    </div>
  )
}

export default Privacy