import React, { useState ,useEffect,useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import './Home.css'
import Navbar from './Navbar';
import support from '../images/support.png'
import HomeImg from '../images/Homepage2.png'
import search from '../images/Search (1).png'
import time from '../images/Back in time.png'
import analysis from '../images/Analysis.png'
import payment from '../images/Payment.png'
import guarantee from '../images/Guarantee.png'
import whyfazco from '../images/whyfazco.png'
import suzuki from '../images/japanese/Suzuki.png'
import toyota from '../images/japanese/Toyota.png'
import nissan from '../images/japanese/Nissan.png'
import honda from '../images/japanese/Honda.png'
import mazda from '../images/japanese/Mazda.png'
import subaru from '../images/japanese/Subaru.png'
import daihatsu from '../images/japanese/Daihatsu.png'
import mitsubishi from '../images/japanese/Mitsubishi.png'
import hino from '../images/japanese/Hino.png'
import febi from '../images/japanese/febi.png'
import hyunda from '../images/japanese/hyunda.png'
import visteon from '../images/japanese/visteon.png'
import isuzu from '../images/japanese/Isuzu.png'
import acdelco from '../images/japanese/acdelco.png'
import hyundai from '../images/korean/Hyundai.png'
import kia from '../images/korean/Kia.png'
import ssangyong from '../images/korean/SsangYong.png'
import genesis from '../images/korean/Genesis.png'
import daewoo from '../images/korean/Daewoo.png'
import arrow1 from '../images/Arrow 1.png'
import byd from '../images/chinese/BYD.png'
import changan from '../images/chinese/Changan.png'
import hongqi from '../images/chinese/Hongqi.png'
import geely from '../images/chinese/Geely.png'
import gac from '../images/chinese/GAC.png'
import saic from '../images/chinese/SAIC Motor.png'
import jac from '../images/chinese/JAC Motors.png'
import baic from '../images/chinese/BAIC Motor.png'
import chery from '../images/chinese/Chery.png'
import greatwall from '../images/chinese/Great Wall.png'
import ford from '../images/american/Ford.png'
import chevrolet from '../images/american/Chevrolet.png'
import gm from '../images/american/gm.png'
import cadillac from '../images/american/Cadillac.png'
import buick from '../images/american/Buick.png'
import gmc from '../images/american/GMC.png'
import dodge from '../images/american/Dodge.png'
import chrysler from '../images/american/Chrysler.png'
import jeep from '../images/american/Jeep.png'
import linchon from '../images/american/Lincoln.png'
import tesla from '../images/american/Tesla.png'
import benz from '../images/german/Mercedes Benz.png'
import audi from '../images/german/Audi.png'
import bmz from '../images/german/BMW.png'
import volkswagen from '../images/german/Volkswagen.png'
import skoda from '../images/german/Skoda.png'
import opel from '../images/german/Opel.png'
import jaguar from '../images/german/Jaguar.png'
import bentley from '../images/german/Bentley.png'
import seat from '../images/german/SEAT.png'
import mg from '../images/german/MG (1).png'
import astonmartin from '../images/german/Aston Martin.png'
import landrover from '../images/german/Land Rover.png'
import mini from '../images/german/Mini.png'
import rollsroys from '../images/german/Rolls Royce.png'
import lamborghini from '../images/german/Lamborghini.png'
import alfaromeo from '../images/german/Alfa Romeo.png'
import porsche from '../images/german/Porsche (1).png'
import mclaren from '../images/german/McLaren.png'
import renault from '../images/french/Renault.png'
import peugeot from '../images/french/Peugeot.png'
import alpina from '../images/french/Alpina.png'
import buggatti from '../images/french/Bugatti.png'
import citroen from '../images/french/Citroen.png'
import ds from '../images/french/DS.png'
import Footer from './Footer';
import Support from './Support';



function Home() {

  const [selectedBrand, setSelectedBrand] = useState('Japanese');

  const [selectedBrandTwo, setSelectedBrandTwo] = useState('Chinese');

  const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
  };

  const handleBrand2Click = (brand) => {
    setSelectedBrandTwo(brand);
  };

  const navigate = useNavigate(); // Initialize navigate

  const handleAMPOpenClick = () => {
    navigate('/amp');
  };

  const handleAboutOpenClick = () => {
    navigate('/about');
  };

  const [showSupport, setShowSupport] = useState(false);

  const toggleSupport = () => {
    setShowSupport(!showSupport);
  };

  return (
    <div className='mainDiv'>
      <div className='Navbar'>
        <Navbar />
      </div>
      <div className='homeMainImgDiv'>
        <div className='homeMainImgSupport'>
          <img className='supportAnimation' src={support} alt='support' onClick={toggleSupport} />
          {showSupport && <Support onClose={toggleSupport} />}

        </div>
        {/* <img src={HomeImg} alt='HomeImg' style={{ width: '100%', height: '100%' }} /> */}
        <div className='homeViewMore' onClick={handleAboutOpenClick}>View More</div>

      </div>
      <div className='homeContentMainDiv'>
        <div className='homeLogoDiv'>
          <div className='homeLogoDivSub'>
            <div className='homeLogoDivSubCol1'>
              <div className='homeCol1Ecclipse'>
                <img className='homeCol1EcclipseCircle' src={search} alt='search' />
              </div>
              <span className='homeCol1Span'>Transparent and safe Business</span>
            </div>
            <div className='homeLogoDivSubCol1'>
              <div className='homeCol1Ecclipse'>
                <img className='homeCol1EcclipseCircle' src={time} alt='time' />

              </div>
              <span className='homeCol1Span'>Fast and Accurate order Processing</span>
            </div>
            <div className='homeLogoDivSubCol1'>
              <div className='homeCol1Ecclipse'>
                <img className='homeCol1EcclipseCircle' src={analysis} alt='analysis' />

              </div>
              <span className='homeCol1Span'>Flexible Client<br /> Service</span>
            </div>
            <div className='homeLogoDivSubCol1'>
              <div className='homeCol1Ecclipse'>
                <img className='homeCol1EcclipseCircle' src={payment} alt='payment' />

              </div>
              <span className='homeCol1Span'>Convenient payment methods</span></div>
            <div className='homeLogoDivSubCol1'>
              <div className='homeCol1Ecclipse'>
                <img className='homeCol1EcclipseCircle' src={guarantee} alt='guarantee' />

              </div>
              <span className='homeCol1Span'>Safety and Quality gurantee</span>
            </div>
          </div>

        </div>
        <div className='homeWhyfazcoDiv'>
          <div className='homeWhyfazcoDivSub'>
            <span className='homeWhyfazcoHead'>Why FAZCO?</span>
            <span className='homeWhyfazcoHeadSub'>
              Fazco Auo Parts Trading" is one of the company in Leading Automotive Genuine & Aftermarket spare parts.
              We supply auto spare parts for Japanese,
              and Korean,American cars. Our range of products includes Genuine and duplicate spare parts for passenger  vehicles.
            </span>
            <div className='homeWhyfazcoDivSubImg'>
              <img src={whyfazco} alt='whyfazco' style={{ width: '100%', height: '100%' }} />
            </div>
          </div>

        </div>
        <br />
        <div className='homeBrandsMainDiv'>
          <div className='homeBrandsMainDivSub'>
            <span className='homeBrandsHead'>Brands you can Shop for</span>
            <div className='homeBrandsTable'>
              <div className='homeBrandsTableRow1'>
                <div className={`homeBrandsTableRow1Divs ${selectedBrand === 'Japanese' ? 'active' : ''}`} onClick={() => handleBrandClick('Japanese')}>Japanese Brand</div>
                <div className={`homeBrandsTableRow1Divs ${selectedBrand === 'American' ? 'active' : ''}`} onClick={() => handleBrandClick('American')}>American Brand</div>
                <div className={`homeBrandsTableRow1Divs ${selectedBrand === 'German' ? 'active' : ''}`} onClick={() => handleBrandClick('German')}>German & European</div>

              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'Japanese' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={suzuki} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={toyota} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={nissan} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={honda} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={mazda} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={subaru} alt='suzuki' />

                  </div>
                </div>
              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'Japanese' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={daihatsu} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={mitsubishi} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={hino} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={isuzu} alt='suzuki' />

                  </div>
                </div>
                {/* <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={febi} alt='suzuki' />

                  </div>
                </div> */}
                {/* <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={hyunda} alt='suzuki' />
                    <span className='imgSpan'>HYUNDAI MOBIS</span>
                  </div>
                </div> */}
                {/* <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={visteon} alt='suzuki' />
                    <span className='imgSpan'>VISTEON</span>


                  </div>
                </div> */}
              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'Japanese' ? 'flex' : 'none' }}>
          
                {/* <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={acdelco} alt='suzuki' />

                  </div>
                </div> */}

              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'American' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={ford} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={chevrolet} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={gm} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={cadillac} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={buick} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={gmc} alt='suzuki' />

                  </div>
                </div>
              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'American' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={linchon} alt='lincholn' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={dodge} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={chrysler} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={tesla} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={jeep} alt='suzuki' />

                  </div>
                </div>


              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'American' ? 'flex' : 'none' }}>

              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'German' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={benz} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={audi} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={bmz} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={volkswagen} alt='suzuki' />

                  </div>

                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={skoda} alt='suzuki' />

                  </div>

                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={opel} alt='suzuki' />

                  </div>
                </div>
              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'German' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={jaguar} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={bentley} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={seat} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={mg} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={astonmartin} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={landrover} alt='suzuki' />

                  </div>
                </div>
              </div>
              <div className='homeBrandsTableRow2' style={{ display: selectedBrand === 'German' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={mini} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={rollsroys} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={lamborghini} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={alfaromeo} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={porsche} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={mclaren} alt='suzuki' />

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className='homeFreeDiv'></div>
        <div className='homeBrandsMainDivNew'>
          <div className='homeBrandsMainDivSubNew'>
            <div className='homeBrandsTable'>
              <div className='homeBrandsTableRow1New'>
                <div className={`homeBrandsTableRow1Divs ${selectedBrandTwo === 'Chinese' ? 'active' : ''}`} onClick={() => handleBrand2Click('Chinese')}>
                  Chinese Brand
                </div>
                <div className={`homeBrandsTableRow1Divs ${selectedBrandTwo === 'Korean' ? 'active' : ''}`} onClick={() => handleBrand2Click('Korean')}>
                  Korean Brand
                </div>
                <div className={`homeBrandsTableRow1Divs ${selectedBrandTwo === 'French' ? 'active' : ''}`} onClick={() => handleBrand2Click('French')}>
                  French Brand
                </div>

              </div>
              <div className='homeBrandsTableRow2New' style={{ display: selectedBrandTwo === 'Chinese' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={byd} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={changan} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={hongqi} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={geely} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={gac} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={saic} alt='suzuki' />

                  </div>
                </div>
              </div>
              <div className='homeBrandsTableRow2New' style={{ display: selectedBrandTwo === 'Chinese' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={jac} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={baic} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={chery} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={greatwall} alt='suzuki' />

                  </div>
                </div>

              </div>

              <div className='homeBrandsTableRow2New' style={{ display: selectedBrandTwo === 'Korean' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={hyundai} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={kia} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={ssangyong} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={genesis} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={daewoo} alt='suzuki' />

                  </div>
                </div>

              </div>
              <div className='homeBrandsTableRow2New' style={{ display: selectedBrandTwo === 'Korean' ? 'flex' : 'none' }}>

              </div>
              <div className='homeBrandsTableRow2New' style={{ display: selectedBrandTwo === 'French' ? 'flex' : 'none' }}>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={renault} alt='suzuki' />
                    <span className='imgSpan'>RENAULT</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={peugeot} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={alpina} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={buggatti} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={citroen} alt='suzuki' />

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <img className='homeBrandsPicImg' src={ds} alt='suzuki' />

                  </div>
                </div>
              </div>
              <div className='homeBrandsTableRow2New' style={{ display: selectedBrandTwo === 'French' ? 'flex' : 'none' }}>


              </div>
              <div className='homeBrandsTableRow2'>



              </div>
            </div>
          </div>
        </div>

        <div className='homeAfterMarketDiv'>
          <div className='homeAfterMarketSub'>
            <span className='homeAfterMarketHead'>After Market Products</span>
            <div className='homeAfterMarketTable'>
              <div className='homeBrandsTableRow2'>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>FEBEST</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>CFA FRANCE</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>BOSCH</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>FEBI BILSTEIN</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>DAYCO</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>RENKEN</span>

                  </div>
                </div>
              </div>
              <div className='homeBrandsTableRow2'>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>VALEO</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>NSK</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>KASHIYAMA</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>HIQ</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>DAWOON</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>HELLA</span>

                  </div>
                </div>
              </div>
              <div className='homeBrandsTableRow2'>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>KAYABA</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>HYUNDAI  MOBIS</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>NGK</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>HDK</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>GNB</span>

                  </div>
                </div>
                <div className='homeBrandsTableRow2Divs'>
                  <div className='homeBrandsPic'>
                    <span className='imgSpan2'>LUMAX</span>

                  </div>
                </div>

              </div>

            </div>

          </div>
          <div className='homeAfterMarketSeeAll' onClick={handleAMPOpenClick}>
            <span className='homeAfterMarketSeeAllSpan'>See All</span>
            <img src={arrow1} alt='arrow' />
          </div>
        </div>
        <div className='homeFreeDiv'>

        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Home