import React from 'react'
import './Footer.css'
import { useNavigate } from 'react-router-dom';
import facebook from '../images/facebook.png'
import instagram from '../images/insta.png'
import mail from '../images/mail.png'
import logo from '../images/newlogo.png'


function Footer() {

  const navigate = useNavigate(); // Initialize navigate

  const customerType = sessionStorage.getItem('customerType');


  const handleHomeOpenClick = () => {
    navigate('/');
  };

  const handlePrivacyOpenClick = () => {
    navigate('/privacy');
  };

  const handleTermsOpenClick = () => {
    navigate('/terms');
  };

  const handleContactOpenClick = () => {
    navigate('/contactus');
  };

  const handleAboutOpenClick = () => {
    navigate('/about');
  };

  const handleStartOpenClick = () => {
    navigate('/start');
  };


  return (
    <div className='footerMainDiv'>
      <div className='footerMainDivSub'>
        <div className='footerMainDivSubCol1'>
          <div className='footerMainDivSubCol1Logo'>
            <img alt='logo' src={logo} style={{ width: '100%', height: '100%' }} />
          </div>
          <div className='footerMainDivSubCol1Content'>
            Fazco Auto Parts Trading is one of the company in Leading Automotive Genuine & Aftermarket spare parts.
          </div>
          <div className='footerMainDivSubCol1Ecllipse'>
            <div className='footerMainDivSubCol1Ecllipse1' >
              <img className='imageFooterView' alt='fb' src={facebook} />

            </div>
            <div className='footerMainDivSubCol1Ecllipse2'>
              <img className='imageFooterView' alt='fb' src={instagram} />

            </div>
            <div className='footerMainDivSubCol1Ecllipse3'>
              <img className='imageFooterView' alt='fb' src={mail} />

            </div>
          </div>
        </div>
        <div className='footerMainDivSubCol2'>
          <div className='footerMainDivSubCol2SubHead'>
            <span className='footerMainDivSubCol2Head'>Link</span>
            <span className='footerMainDivSubCol2Sub' onClick={handleHomeOpenClick}>{'>'} Home</span>
            <span className='footerMainDivSubCol2Sub' onClick={handleAboutOpenClick}>{'>'} About Us</span>
            <span className='footerMainDivSubCol2Sub' onClick={handleStartOpenClick}>{'>'} Start</span>
            <span className='footerMainDivSubCol2Sub' onClick={handleContactOpenClick}>{'>'} Contact Us</span>

          </div>




        </div>
        <div className='footerMainDivSubCol3'>
          <div className='footerMainDivSubCol2SubHead'>
            <span className='footerMainDivSubCol2Head'>Resources</span>
            <span className='footerMainDivSubCol2Sub' onClick={handlePrivacyOpenClick} >{'>'} Privacy Policy</span>
            <span className='footerMainDivSubCol2Sub' onClick={handleTermsOpenClick}>{'>'} Terms & Conditions</span>

          </div>
        </div>
        <div className='footerMainDivSubCol4'>
          <div className='footerMainDivSubCol2SubHead'>
            <span className='footerMainDivSubCol2Head'>Useful Link</span>
           

            <span className='footerMainDivSubCol2Sub'>{'>'} Catalogue</span>
            <span className='footerMainDivSubCol2Sub'>{'>'} Brand</span>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer