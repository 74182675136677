import React,{useEffect,useState} from 'react'
import './Terms.css'
import Navbar from './Navbar'
import facebook from '../images/facebook.png'
import instagram from '../images/insta.png'
import mail from '../images/mail.png'
import Footer from './Footer'
import NavbarMob from './NavbarMob';
import FooterMob from './FooterMob'

function Terms() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        // Update the screen size on resize
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Attach event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='mainDiv'>
            <div className='Navbar'>
            {isMobile ? <NavbarMob /> : <Navbar />}
            </div>

            <div className="privacy_root">
                <div className="privacyContent">
                    <br />
                    <div className="privacy_title">Terms & Conditions</div>
                    <br></br>
                    <label>Use of the Website</label>
                    <br />
                    <br />
                    <br />


                    <section>
                        The website (fazcoautospareparts.com) is provided by FAZCO AUTOPARTS
                        having its registered office at  India,
                        WADI AL NEEL AUTO SPARE PARTS TRADING .LLC
                        Authorised Distributer Fazco Auto Parts
                        UAE,Ajman.Ind,2 <br></br>
                        <br></br>You hereby agree to accept the terms and conditions set forth in this Terms of Use. In any event or situation,
                        if you are not agreeing with these terms and conditions, you are requested by FAZCO AUTOPARTS not to use the website or download
                        any material from it.<br />
                        <br /> The website, including all the material (excluding any applicable third party materials), is the property of FAZCO AUTOPARTS ,
                        and it is copyrighted and protected by worldwide copyright laws and treaty provisions. You hereby approve to follow all
                        the copyright laws worldwide in your use of this website and to prevent any unauthorized copying of the material.<br />
                        FAZCO AUTOPARTS is maintaining business relationships with its thousands of customers, suppliers, governments and others.
                        We have used words like joint venture, partnership, and partner in order to indicate business relationships
                        involving common activities and interests, and words may not indicate precise legal relationships.<br />
                        <br />On regular basis, this website may also include some links to other websites. These links are mainly provided for your
                        convenience to deliver further information. They do not signify that we are endorsing this website.

                    </section>
                    <br />
                    <br />
                    <label>Use of the Licence</label>
                    <br />
                    <br />

                    <section>
                        <br />

                        Considering the terms and condition that are set forth in these terms and condition, FAZCO AUTOPARTS has granted the permission to
                        temporarily download the copy of the materials (information or services) on FAZCO AUTOPARTS website for personal, non-commercial transitory
                        viewing only. This is the grant of a license, not a transfer of title.
                        You also agree that you will not interrupt or attempt to interrupt the operation of the website in any manner.
                        <br /> <br />
                        Unless specified, the website is for your personal and non-commercial use. Ensure that you shall not change, modify,
                        copy and distribute display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information,
                        software, products or services obtained from this website.


                    </section>
                    <br />
                    <br />
                    <label>Blog</label>
                    <br />
                    <br />
                    <section>
                        <br />
                        This is to inform that individuals (including, but not limited to employees of FAZCO AUTOPARTS and referred to as “Individuals”) may
                        contribute articles, blog content and opinions on this website that is the sole discretion of FAZCO AUTOPARTS. Such contribute contents
                        and articles are referred as the “blogs” because such term is easily understood. You agree that these blogs and
                        contents found the opinion of the individuals in their personal capacity and do not signify the
                        official positions of FAZCO AUTOPARTS. We hold all the copyright to these blog contents.

                    </section>
                    <br />
                    <br />
                    <label>Disclaimer</label>
                    <br />
                    <br />

                    <br />
                    <section>
                        The information mentioned in this website is for general purpose only. We endeavour to keep this information updated and correct.
                        The company does not warrant the accuracy or completeness of the materials or the reliability of any advice, opinion, statement or
                        other information that displayed or distributed through the website.<br />
                        <br />

                        You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk.
                        FAZCO AUTOPARTS reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the website.
                        <br />
                        <br />
                        FAZCO AUTOPARTS reserves the right to make any other changes to the website, the materials and the products,
                        programs, services or prices (if any) described in the website at any time without any prior notice.
                    </section>
                    <br />
                    <br />

                    <label>Unlawful or Prohibited Use of the Website</label>
                    <br />
                    <br />

                    <br />
                    <section>
                        You shall not use the website for any purpose that is unlawful or prohibited by the Terms of Use. You are not
                        allowed to use the website in any manner that could damage, disable, overburden or
                        impair any FAZCO AUTOPARTS server, or the network connected to any FAZCO AUTOPARTS server.<br />
                        <br />
                        You should not attempt to get unauthorized access to any section of the website, accounts, computer systems or networks
                        connected to any FAZCO AUTOPARTS server or to any of the services related with the website through hacking,
                        password mining or any other manner. Ensure that you do not obtain or attempt to get any material or information in
                        anyway not intentionally made available through the website. In case, if we find that you have violated this term,
                        we reserve the right to terminate your use of our mobile app development service at any time.
                    </section>
                    <br />
                    <br />

                    <label>Modifications in Terms of Use</label>
                    <br />
                    <br />

                    <br />
                    <section>
                        FAZCO AUTOPARTS reserves the right to change, modify, add or remove any portion of the Terms of Use in whole or in part, at any time.
                        The modification or change in Terms of Use will be effective once the notice of such changes is posted.
                        If you are continuing the use of the website after
                        modification in Terms of Use, it will be considered as the acceptance of such modifications.<br />
                        <br />
                        FAZCO AUTOPARTS also reserves the right to change, terminate, suspend or discontinue any service of the website,
                        including the feature of the service or website anytime without prior notice. Along with this,
                        FAZCO AUTOPARTS may also execute limits on features and services. It can also restrict your access to particular
                        sections or the entire website without any notice. You acknowledge and agree that FAZCO AUTOPARTS may terminate the authorization,
                        rights and license given above at any point of time at its own sole discretion.
                    </section>
                    <br />

                </div>
                <div className="privacyContentFooter">
                    <div className="privacyContentFooterdiv">
                        <div className="privacyContentFooterdivpart">
                            <label className="HaveQues">Have Questions?</label>
                            <label className="getintouch">Get in touch with us</label>
                            <label className="mobilenum"> 00971- 54 347 64 17</label>
                        </div>
                        <div className="divseperation"></div>
                        <div className="privacyContentFooterdivpart">
                            <label className="privacyEmail">Email</label>
                            <label className="privacyEmailaddress">
                                fazcoautoparts@gmail.com
                            </label>
                        </div>
                        <div className="divseperation"></div>

                        <div className="privacyContentFooterdivpart">
                            <label className="privacyfollowus">Follow Us</label>
                            <div className="socialiconsdiv">
                                <div className="socialiconspart">
                                    <div className="socialicons">
                                        <img src={facebook} alt="fb" />
                                    </div>
                                </div>
                                <div className="socialiconspart">
                                    <div className="socialicons">
                                        <img src={instagram} alt="X" />
                                    </div>
                                </div>
                                <div className="socialiconspart">
                                    <div className="socialicons">
                                        <img src={mail} alt="in" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {isMobile ? <FooterMob /> : <Footer />}

            </div>


        </div>
    )

}

export default Terms