import React,{useState,useEffect} from 'react'
import NavbarMob from './NavbarMob'
import search from '../images/HomeMob/search.png'
import { useNavigate } from 'react-router-dom';
import NavbarMob2 from './NavbarMob2';

function TablesMob() {

    const [products, setProducts] = useState([]);
    const [subProducts, setSubProducts] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [filteredData, setFilteredData] = useState([]);
    const [subfilteredData, setSubFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data from API
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/priceuploadget`);
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [apiBaseUrl]);

    useEffect(() => {
        // Fetch data from API
        const fetchSubData = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/subpriceget`);
                const data = await response.json();
                setSubProducts(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchSubData();
    }, [apiBaseUrl]);

    useEffect(() => {
        setFilteredData(
            products.filter(
                item =>
                    item.PARTCODE.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.NAME.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setSubFilteredData(
            subProducts.filter(
                subItem =>
                    subItem.SUBPARTCODE.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    subItem.NAME.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );

      
    }, [searchQuery, products,subProducts]);

    const handleSearch = event => {
        setSearchQuery(event.target.value);
    };

    const handleProductClick = (product) => {
        const { NAME, PARTCODE, SUBPARTCODE, MODEL, BRAND, PRICE, QTY, DELIVERYTIME, WEIGHT, SUPPLIERNAME, UPLOADDATE, SUPPLIERCOST,MANUFACTURER } = product;
        // Save product data to sessionStorage
        sessionStorage.setItem('productData', JSON.stringify({
            NAME: NAME || '',
            PARTCODE: PARTCODE || '',
            SUBPARTCODE: SUBPARTCODE || '',
            MODEL: MODEL || '',
            BRAND: BRAND || '',
            PRICE: PRICE || '',
            QTY: QTY || '',
            DELIVERYTIME: DELIVERYTIME || '',
            WEIGHT: WEIGHT || '',
            SUPPLIERNAME: SUPPLIERNAME || '',
            UPLOADDATE: UPLOADDATE || '',
            SUPPLIERCOST: SUPPLIERCOST || '',
            MANUFACTURER: MANUFACTURER || '',
        }));
        // Navigate to ProductDetailPage
        navigate(`/product`);
    };
    
    

    return (
        <div className='min-h-screen flex flex-col  items-center bg-[#e4e2e2]'>
            <NavbarMob2 />
            <div className='px-3  h-[50px] w-full mt-5 '>
                <div className=' w-full h-full  flex flex-row '>
                    <div className='w-[90%] h-full'>
                        <input className='w-full h-full px-5' placeholder='Search Product Name' value={searchQuery} onChange={handleSearch} />
                    </div>
                    <div className='w-[10%] h-full flex justify-center items-center bg-[white]'>
                        <img src={search} alt='search' />
                    </div>
                </div>
            </div>

            <div className='w-full h-auto px-3 justify-center items-center mt-5'>
                <span className='text-base font-[600] font-display'>Products</span>
                <div className='grid  grid-cols-1 w-full gap-2 mt-5'>
                {filteredData.map((product, index) => (
                        <div
                            key={product.id}
                            className='h-[95px] border-2 border-[#FF9A00] bg-[white] rounded-[7px] flex flex-col justify-center items-center px-1 gap-1'
                            onClick={() => handleProductClick(product)}
                        >
                            <span className='text-sm font-[500] font-display'>{product.NAME}</span>
                            <span className='text-sm font-[400] font-display'>{product.PARTCODE}</span>
                        </div>
                    ))}
                </div>
            </div>
            {searchQuery && subfilteredData.length > 0 && (
            <div className='w-full h-auto px-3 justify-center items-center mt-5'>
                <span className='text-base font-[600] font-display'>Sub Products</span>
                <div className='grid  grid-cols-1 w-full gap-2 mt-5'>
                {subfilteredData.map((product, index) => (
                        <div
                            key={product.id}
                            className='h-[95px] border-2 border-[#FF9A00] bg-[white] rounded-[7px] flex flex-col justify-center items-center px-1 gap-1'
                            onClick={() => handleProductClick(product)}
                        >
                            <span className='text-sm font-[500] font-display'>{product.NAME}</span>
                            <span className='text-sm font-[400] font-display'>{product.SUBPARTCODE}</span>
                        </div>
                    ))}
                </div>
            </div>
             )}
        </div>
    )
}

export default TablesMob
