import React, { useState, useEffect, useRef } from 'react';
import './PriceUpload.css';
import Navbar from './Navbar';
import dropdown from '../images/dropdown.png';
import Drawer from './Drawer';
import NavbarMob from './NavbarMob';

function PriceUpload() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        // Update the screen size on resize
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Attach event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [currentDate, setCurrentDate] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [focusedInputIndex, setFocusedInputIndex] = useState(null);
    const inputRefs = useRef([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    console.log(apiBaseUrl);

    const toggleDrawer = () => {
        setIsDrawerOpen(prevState => !prevState);
    };
    const [formData, setFormData] = useState({
        PARTCODE: '',
        NAME: '',
        MODEL: '',
        BRAND: '',
        PRICE: '',
        QTY: '',
        DELIVERYTIME: '',
        WEIGHT: '',
        SUPPLIERNAME: '',
        UPLOADDATE: '',
        SUPPLIERCOST: '',
        MANUFACTURER:''
    });

    useEffect(() => {
        const getCurrentDate = () => {
            const dateObj = new Date();
            const day = String(dateObj.getDate()).padStart(2, '0');
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero based
            const year = dateObj.getFullYear();
            return `${day}-${month}-${year}`;
        };

        setCurrentDate(getCurrentDate());
    }, []);

    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            } else {
                // Focus on the submit button if the last input is reached
                // You can add your submit logic here
                // For example: inputRefs.current[index].blur();
            }
        }
    };

    const handleInputFocus = (index) => {
        setFocusedInputIndex(index);
    };

    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: value
        }));
    };

    const submitForm = () => {

        const updatedFormData = {
            ...formData,
            UPLOADDATE: currentDate
        };
        
        // Check if all fields in updatedFormData are filled
        const isFormFilled = Object.values(updatedFormData).every(value => value !== '');
    
        if (isFormFilled) {
            fetch(`${apiBaseUrl}/priceupload`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedFormData)
            })
            .then(response => {
                if (response.ok) {
                    return response.text();
                }
                throw new Error('Form data submission failed');
            })
            .then(data => {
                console.log(data); // Log success message
                alert('Form submitted successfully');
                // Reload the page
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle error
            });
        } else {
            alert('Please fill in all fields before submitting.');
        }
    };
    
    useEffect(() => {
        // Focus on the first input field when component mounts
        inputRefs.current[0].focus();
    }, []); 

    return (
        <div className='mainDiv'>
            <div className='Navbar'>
            {isMobile ? <NavbarMob /> : <Navbar />}
            </div>
            <div className='topBar'>
                <div className='topBarSub'>
                    <div className='topBarSubImg' onClick={toggleDrawer}>
                        <img className='topBarSubImgPic' alt='dropdown' src={dropdown} />
                    </div>
                    <div className='topBarSubHead'>PRICE UPLOAD</div>
                    <div className='topBarSubHeadFree'></div>
                </div>
            </div>
            <div className='priceUploadMainDiv'>
                {isDrawerOpen && <Drawer isOpen={isDrawerOpen} />}
                <div className='priceUploadDivBorder'>
                    <div className='priceUploadDivBorderRow1'>
                        <div className='priceUploadDivBorderCol1'>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>PART CODE</label>
                                <input
                                    ref={el => (inputRefs.current[0] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 0 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 0)}
                                    onFocus={() => handleInputFocus(0)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'PARTCODE')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>MODEL</label>
                                <input
                                    ref={el => (inputRefs.current[2] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 2 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 2)}
                                    onFocus={() => handleInputFocus(2)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'MODEL')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>PRICE</label>
                                <input
                                    type='number'

                                    ref={el => (inputRefs.current[4] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 4 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 4)}
                                    onFocus={() => handleInputFocus(4)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'PRICE')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>DELIVERY TIME</label>
                                <input
                                    ref={el => (inputRefs.current[6] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 6 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 6)}
                                    onFocus={() => handleInputFocus(6)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'DELIVERYTIME')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>SUPPLIER NAME</label>
                                <input
                                    ref={el => (inputRefs.current[8] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 8 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 8)}
                                    onFocus={() => handleInputFocus(8)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'SUPPLIERNAME')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>UPLOAD DATE</label>
                                <input
                                    className='priceUploadDivBorderCol1Input'
                                    value={currentDate}
                                    readOnly
                                />
                            </div>
                            <div className='priceUploadDivBorderColContentButton' onClick={submitForm}>SUBMIT</div>
                        </div>
                    </div>
                    <div className='priceUploadDivBorderRow2'>
                        <div className='priceUploadDivBorderCol2'>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>NAME</label>
                                <input
                                    ref={el => (inputRefs.current[1] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 1 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 1)}
                                    onFocus={() => handleInputFocus(1)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'NAME')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>BRAND</label>
                                <input
                                    ref={el => (inputRefs.current[3] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 3 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 3)}
                                    onFocus={() => handleInputFocus(3)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'BRAND')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>QTY</label>
                                <input
                                    type='number'

                                    ref={el => (inputRefs.current[5] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 5 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 5)}
                                    onFocus={() => handleInputFocus(5)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'QTY')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>WEIGHT</label>
                                <input
                                

                                    ref={el => (inputRefs.current[7] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 7 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 7)}
                                    onFocus={() => handleInputFocus(7)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'WEIGHT')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>SUPPLIER COST</label>
                                <input
                                    type='number'

                                    ref={el => (inputRefs.current[9] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 9 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 9)}
                                    onFocus={() => handleInputFocus(9)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'SUPPLIERCOST')}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>MANUFACTURE</label>
                                <input
                                    

                                    ref={el => (inputRefs.current[10] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 10 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 10)}
                                    onFocus={() => handleInputFocus(10)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'MANUFACTURER')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PriceUpload;
