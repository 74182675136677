import React,{useState,useEffect} from 'react'
import './Start.css'
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import NavbarMob from './NavbarMob';
import FooterMob from './FooterMob';



function Start() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
      // Update the screen size on resize
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };

      // Attach event listener
      window.addEventListener('resize', handleResize);

      // Clean up the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const navigate = useNavigate(); // Initialize navigate
  const handleHomeOpenClick = () => {
    navigate('/');
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
});

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
};

const handleSendEmail = () => {
  const recipientEmail = 'fazcoautoparts@gmail.com'; // Update with the recipient's email address
  const subject = 'New Message from Start Page';
  const body = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${formData.message}`;

  // Open default email client with pre-filled email template
  window.open(`mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);

  // Clear form data
  setFormData({
      name: '',
      email: '',
      phone: '',
      message: ''
  });

  // Optionally, you can redirect or perform any other action after sending the email
};



  return (
    <div className='mainDiv'>
      <div className='Navbar'>
      {isMobile ? <NavbarMob /> : <Navbar />}
      </div>
      <div className='startMainDiv'>
        <div className='startMainDivSub'>
          <div></div>
          <span className='startMainDivRoute'>
            <span className='ampHeadRouteSpan' onClick={handleHomeOpenClick}>Home</span> {'>'} Start
          </span>
          <div className='startHeadDiv'>
            If you want to start working with us !
          </div>
          <div className='startDiv'>
            <span className='startHeadSub'>1. Register on our Website </span>
            <span className='startContentSub'>This is a common procedure. It will take only few minutes</span>
          </div>
          <div className='startDiv'>
            <span className='startHeadSub'>2. Fill the Application Form </span>
            <span className='startContentSub'>After registration we will ask you to fill in an application
              form to understand your needs and make the best price offer for you</span>
          </div>
          <div className='startDiv'>
            <span className='startHeadSub'>3. Get Access to our price lists </span>
            <span className='startContentSub'>After reviewing your application form –
              during one work day – we will provide you an access to our website and to our spare parts’ prices</span>
          </div>
          <div className='startDiv'>
            <span className='startHeadSub'>4. Confirm your interest in a Partnership </span>
            <span className='startContentSub'>If you are satisfied with our prices we will ask you for next information
              you may be interested in: documents, delivery and payment. After that if you are ready to start business with
              us we will assign a personal manager to you</span>
          </div>
          <div className='startDiv'>
            <span className='startHeadSub'>5. Follow your personal manager’s instruction </span>
            <span className='startContentSub'>We are sure your communication with us will be easy
              and pleasant. In all cases you have an opportunity to write directly to our General Manager</span>
          </div>
          <div className='startMessageDiv'>
            <div className='startMessageDivSub'>
              <div className='startMessageDivSubBorder'>
                <span className='startMessageHead'>
                  Write a Message
                </span>
                <div>
                        <input
                            className='startMessageInput'
                            placeholder='Name'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            className='startMessageInput'
                            placeholder='Email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            className='startMessageInput'
                            placeholder='Phone No'
                            name='phone'
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            className='startMessageInput2'
                            placeholder='Text'
                            name='message'
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='startMessageButton' onClick={handleSendEmail}>
                        Send
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? <FooterMob /> : <Footer />}
    </div>
  )
}

export default Start