import React from 'react'
import './Navbar.css'
import logo from '../images/fazcologonew.png'
import { useNavigate } from 'react-router-dom';


function Navbar() {
    const navigate = useNavigate(); // Initialize navigate

    const handleHomeOpenClick = () => {
      navigate('/'); 
    };

    const handleLoginOpenClick = () => {
        navigate('/login'); 
      };
  
      const handleContactOpenClick = () => {
        navigate('/contactus'); 
      };

      const handleAboutOpenClick = () => {
        navigate('/about'); 
      };
      const handleStartOpenClick = () => {
        navigate('/start'); 
      };

      const handleTableOpenClick = () => {
        navigate('/table'); 
      };

  return (
    <div className='navbarMainDiv'>
        <div className='navbarMainDivSub'>
            <div className='navbarLogo'>
                <img src={logo} alt='logo' style={{height:'95%',width:'auto',...(window.innerWidth <= 768 && { height: '70%', width: '90%' }),}} />
            </div>
            <div className='navbarLinks'>
                <span className='navbarLinksSpan'  onClick={handleHomeOpenClick}>Home</span>
                <span className='navbarLinksSpan' onClick={handleAboutOpenClick}>About</span>
                <span className='navbarLinksSpan' onClick={handleTableOpenClick}>Products</span>
                <span className='navbarLinksSpan' onClick={handleStartOpenClick}>Start</span>
                <span className='navbarLinksSpan'  onClick={handleContactOpenClick}>Contact Us</span>

            </div>
            <div className='navbarButton'>
                <div className='navbarButtonSub' onClick={handleLoginOpenClick}>Login / Register</div>
            </div>
        </div>
    </div>
  )
}

export default Navbar