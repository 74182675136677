import React,{useState} from 'react';
import NavbarMob from './NavbarMob';
import bin from '../images/Bin.png';
import {  useNavigate } from 'react-router-dom';

function ProductDetailPage() {
    // Retrieve product data from sessionStorage
    const productData = JSON.parse(sessionStorage.getItem('productData')) || {};
    const [countMap, setCountMap] = useState({}); // Map to track count for each row
    const [countMap2, setCountMap2] = useState({}); // Map to track count for each row
    const navigate = useNavigate();

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // Destructure data with default values
    const {
        NAME = '',
        PARTCODE = '',
        SUBPARTCODE = '',
        MODEL = '',
        BRAND = '',
        PRICE = '',
        QTY = '',
        DELIVERYTIME = '',
        WEIGHT = '',
        SUPPLIERNAME = '',
        UPLOADDATE = '',
        SUPPLIERCOST = '',
        MANUFACTURER = "",
    } = productData;

    // Get customerType from sessionStorage
    const customerType = sessionStorage.getItem('customerType');

    const updateCount = (partCode, operation) => {
        setCountMap(prevCountMap => {
            const currentCount = prevCountMap[partCode] || 0;
            const newCount = operation === '+' ? currentCount + 1 : Math.max(currentCount - 1, 0);
            
            // Only update localStorage if the quantity has actually changed
            if (newCount !== currentCount) {
                const updatedCountMap = { ...prevCountMap, [partCode]: newCount };
                
                // Save to local storage
                const dataToSave = (PARTCODE === partCode);
                if (dataToSave) {
                    const savedData = { ...productData, qty: newCount }; // Save full product data including qty
                    localStorage.setItem(`row-${partCode}`, JSON.stringify(savedData));
                }
    
                return updatedCountMap;
            }
    
            // If quantity hasn't changed, return previous state
            return prevCountMap;
        });
    };

    const updateCount2 = (subpartCode, operation) => {
        setCountMap2(prevCountMap => {
            const currentCount = prevCountMap[subpartCode] || 0;
            const newCount = operation === '+' ? currentCount + 1 : Math.max(currentCount - 1, 0);
            
            // Only update localStorage if the quantity has actually changed
            if (newCount !== currentCount) {
                const updatedCountMap = { ...prevCountMap, [subpartCode]: newCount };
                
                // Save to local storage
                const dataToSave = (SUBPARTCODE === subpartCode);
                if (dataToSave) {
                    const savedData = { ...productData, qty: newCount }; // Save full product data including qty
                    localStorage.setItem(`row2-${subpartCode}`, JSON.stringify(savedData));
                }
    
                return updatedCountMap;
            }
    
            // If quantity hasn't changed, return previous state
            return prevCountMap;
        });
    };
    
    
    const handlePlaceOrder = () => {
        // Retrieve saved data from local storage
        const emailBody = Object.keys(localStorage)
            .filter(key => key.startsWith('row-') || key.startsWith('row2-'))
            .map(key => {
                const item = JSON.parse(localStorage.getItem(key));
    
                if (item) {
                    const prefix = key.startsWith('row2-') ? 'SUBPARTCODE' : 'PARTCODE';
                    return `${item[prefix] || 'N/A'} \n Name: ${item.NAME || 'N/A'}, Model: ${item.MODEL || 'N/A'}, Brand: ${item.BRAND || 'N/A'}, Price: ${(item.PRICE || 0).toFixed(2)}, QTY: ${item.qty || 0}, Delivery Time: ${item.DELIVERYTIME || 'N/A'}, Weight: ${item.WEIGHT || 'N/A'}, MANUFACTURER: ${item.MANUFACTURER || 'N/A'},\n Total Count: ${item.qty || 0}\n\n`;
                }
    
                return '';
            })
            .join('');
    
        const recipientEmail = 'fazcoautoparts@gmail.com';
        const subject = 'New Order';
    
        // Debugging: Log the email details
        // console.log('Email Body:', emailBody);
        // console.log('Recipient Email:', recipientEmail);
        // console.log('Subject:', subject);
    
        // Ensure emailBody and recipientEmail are defined and not empty
        if (emailBody.trim() && recipientEmail) {
            try {
                const mailtoUrl = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
                console.log('Mailto URL:', mailtoUrl);
    
                // Open default email client with pre-filled email template
                window.location.href = mailtoUrl; // Changed from window.open to window.location.href
            } catch (error) {
                console.error('Error opening email client:', error);
            }
        } else {
            alert('No data to send or recipient email is undefined.');
        }
    
        // Optionally, clear local storage after sending the email
        Object.keys(localStorage)
            .filter(key => key.startsWith('row-') || key.startsWith('row2-'))
            .forEach(key => localStorage.removeItem(key));
    
        window.location.reload();
    };
    
    const handleDelete = async PARTCODE => {
        try {
            const response = await fetch(`${apiBaseUrl}/deleteProperty/${PARTCODE}`, {
                method: 'DELETE'
            });
            if (response.ok) {
            //    console.log("deleted partcode item");
               navigate('/table')
               
            } else {
                console.error('Failed to delete item.');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleSubDelete = async SUBPARTCODE => {
        try {
            const response = await fetch(`${apiBaseUrl}/deletesubpartcode/${SUBPARTCODE}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                navigate('/table')
            } else {
                console.error('Failed to delete item.');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const hasLocalStorageItems = Object.keys(localStorage).filter(key => key.startsWith('row-')).length > 0;
    const hasLocalStorageItems2 = Object.keys(localStorage).filter(key => key.startsWith('row2-')).length > 0;


    return (
        <div className='min-h-screen flex flex-col items-center bg-[#e4e2e2]'>
            <NavbarMob />
            <div className='w-full px-3 h-auto flex justify-center items-center'>
                <div className='bg-[white] w-full mt-5 mb-5 py-7 rounded-[5px] flex flex-col px-3 gap-5'>
                    {PARTCODE && (
                        <div className='flex flex-row w-full'>
                            <div className='w-[40%] text-left text-base font-[600] font-display'>Part Code</div>
                            <div className='w-[60%] gap-5 flex flex-row'>
                                <div className=''>:</div>
                                <div className=''>{PARTCODE}</div>
                            </div>
                        </div>
                    )}
                    {SUBPARTCODE && (
                        <div className='flex flex-row w-full'>
                            <div className='w-[40%] text-left text-base font-[600] font-display'>SubPart Code</div>
                            <div className='w-[60%] gap-5 flex flex-row'>
                                <div className=''>:</div>
                                <div className=''>{SUBPARTCODE}</div>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-row w-full '>
                        <div className='w-[40%] text-left text-base font-[600] font-display'>Name</div>
                        <div className='w-[60%] gap-5 flex flex-row'>
                            <div className=''>:</div>
                            <div className=''>{NAME}</div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full '>
                        <div className='w-[40%] text-left text-base font-[600] font-display'>Model</div>
                        <div className='w-[60%] gap-5 flex flex-row'>
                            <div className=''>:</div>
                            <div className=''>{MODEL}</div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full '>
                        <div className='w-[40%] text-left text-base font-[600] font-display'>Brand</div>
                        <div className='w-[60%] gap-5 flex flex-row'>
                            <div className=''>:</div>
                            <div className=''>{BRAND}</div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full '>
                        <div className='w-[40%] text-left text-base font-[600] font-display'>Price</div>
                        <div className='w-[60%] gap-5 flex flex-row'>
                            <div className=''>:</div>
                            <div className=''>{PRICE}</div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full '>
                        <div className='w-[40%] text-left text-base font-[600] font-display'>Qty</div>
                        <div className='w-[60%] gap-5 flex flex-row'>
                            <div className=''>:</div>
                            <div className=''>{QTY}</div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full '>
                        <div className='w-[40%] text-left text-base font-[600] font-display'>Delivery Time</div>
                        <div className='w-[60%] gap-5 flex flex-row'>
                            <div className=''>:</div>
                            <div className=''>{DELIVERYTIME}</div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full '>
                        <div className='w-[40%] text-left text-base font-[600] font-display'>Weight</div>
                        <div className='w-[60%] gap-5 flex flex-row'>
                            <div className=''>:</div>
                            <div className=''>{WEIGHT}</div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full '>
                        <div className='w-[40%] text-left text-base font-[600] font-display'>MANUFACTURE</div>
                        <div className='w-[60%] gap-5 flex flex-row'>
                            <div className=''>:</div>
                            <div className=''>{MANUFACTURER}</div>
                        </div>
                    </div>
                    {customerType !== 'customer' && (
                        <div className='flex flex-row w-full '>
                            <div className='w-[40%] text-left text-base font-[600] font-display'>Supplier Name</div>
                            <div className='w-[60%] gap-5 flex flex-row'>
                                <div className=''>:</div>
                                <div className=''>{SUPPLIERNAME}</div>
                            </div>
                        </div>
                    )}
                    {customerType !== 'customer' && (
                        <div className='flex flex-row w-full '>
                            <div className='w-[40%] text-left text-base font-[600] font-display'>Supplier Cost</div>
                            <div className='w-[60%] gap-5 flex flex-row'>
                                <div className=''>:</div>
                                <div className=''>{SUPPLIERCOST}</div>
                            </div>
                        </div>
                    )}
                    {customerType !== 'customer' && (
                        <div className='flex flex-row w-full '>
                            <div className='w-[40%] text-left text-base font-[600] font-display'>Upload Date</div>
                            <div className='w-[60%] gap-5 flex flex-row'>
                                <div className=''>:</div>
                                <div className=''>{UPLOADDATE}</div>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-col w-full mt-5 gap-5'>
                        {customerType !== 'admin' && PARTCODE && (
                            <div className='flex flex-row gap-2'>
                                <div className='border-2 border-[black] bg-[#e1e0e0] w-[30px] flex justify-center items-center cursor-pointer'  onClick={() => updateCount(PARTCODE, '-')}>
                                    -
                                </div>
                                <span> {countMap[PARTCODE] || 0}</span>
                                <div className='border-2 border-[black] bg-[#e1e0e0] w-[30px] flex justify-center items-center cursor-pointer'   onClick={() => updateCount(PARTCODE, '+')}>
                                    +
                                </div>
                            </div>
                        )}
                             {customerType !== 'admin' && SUBPARTCODE && (
                            <div className='flex flex-row gap-2'>
                                <div className='border-2 border-[black] bg-[#e1e0e0] w-[30px] flex justify-center items-center cursor-pointer'  onClick={() => updateCount2(SUBPARTCODE, '-')}>
                                    -
                                </div>
                                <span> {countMap2[SUBPARTCODE] || 0}</span>
                                <div className='border-2 border-[black] bg-[#e1e0e0] w-[30px] flex justify-center items-center cursor-pointer'   onClick={() => updateCount2(SUBPARTCODE, '+')}>
                                    +
                                </div>
                            </div>
                        )}
                        {customerType !== 'customer' && PARTCODE && (
                            <div ><img src={bin} style={{ width: '10%', height: '10%' }} alt='bin'  onClick={() => handleDelete(PARTCODE)} /></div>
                        )}
                         {customerType !== 'customer' && SUBPARTCODE && (
                            <div ><img src={bin} style={{ width: '10%', height: '10%' }} alt='bin'  onClick={() => handleSubDelete(SUBPARTCODE)} /></div>
                        )}
                        {(hasLocalStorageItems || hasLocalStorageItems2) &&  customerType !== 'admin' && (
                        <div className='w-[50%] h-[50px] bg-[red] text-[white] rounded-[5px] flex justify-center items-center text-base font-[500] font-display' onClick={handlePlaceOrder}>Place Order</div>
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetailPage;
