import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './HomeMob.css'
import homebg from '../images/HomeMob/homebg.png'
import bgcar from '../images/HomeMob/bgcar.png'
import ring from '../images/HomeMob/ring.png'
import search from '../images/Search (1).png'
import time from '../images/Back in time.png'
import analysis from '../images/Analysis.png'
import support from '../images/support.png'
import payment from '../images/Payment.png'
import guarantee from '../images/Guarantee.png'
import suzuki from '../images/HomeMob/japaneseMob/Suzuki.png'
import toyota from '../images/HomeMob/japaneseMob/Toyota.png'
import nissan from '../images/HomeMob/japaneseMob/Nissan.png'
import honda from '../images/HomeMob/japaneseMob/Honda.png'
import mazda from '../images/HomeMob/japaneseMob/Mazda.png'
import subaru from '../images/HomeMob/japaneseMob/Subaru.png'
import daihatsu from '../images/HomeMob/japaneseMob/Daihatsu.png'
import mitsubishi from '../images/HomeMob/japaneseMob/Mitsubishi.png'
import hino from '../images/HomeMob/japaneseMob/Hino.png'
import isuzu from '../images/HomeMob/japaneseMob/Isuzu.png'
import chevrolet from '../images/HomeMob/americanMob/Chevrolet.png'
import ford from '../images/HomeMob/americanMob/Ford.png'
import gm from '../images/HomeMob/americanMob/gm.png'
import cadilacc from '../images/HomeMob/americanMob/cadilacc.png'
import dodge from '../images/HomeMob/americanMob/Dodge.png'
import chrysler from '../images/HomeMob/americanMob/Chrysler.png'
import buick from '../images/HomeMob/americanMob/Buick.png'
import tesla from '../images/HomeMob/americanMob/Tesla.png'
import gmc from '../images/HomeMob/americanMob/GMC.png'
import jeep from '../images/HomeMob/americanMob/Jeep.png'
import benz from '../images/HomeMob/germanMob/Mercedes Benz.png'
import audi from '../images/HomeMob/germanMob/Audi.png'
import bmw from '../images/HomeMob/germanMob/BMW.png'
import volkswagen from '../images/HomeMob/germanMob/Volkswagen.png'
import skoda from '../images/HomeMob/germanMob/Skoda.png'
import jaguar from '../images/HomeMob/germanMob/Jaguar.png'
import mg from '../images/HomeMob/germanMob/MG.png'
import seat from '../images/HomeMob/germanMob/SEAT.png'
import bentley from '../images/HomeMob/germanMob/Bentley.png'
import opel from '../images/HomeMob/germanMob/Opel.png'
import aston from '../images/HomeMob/germanMob/Aston Martin.png'
import mini from '../images/HomeMob/germanMob/Mini.png'
import landrover from '../images/HomeMob/germanMob/Land Rover.png'
import lamborghini from '../images/HomeMob/germanMob/Lamborghini.png'
import rollsroyce from '../images/HomeMob/germanMob/Rolls Royce.png'
import alfa from '../images/HomeMob/germanMob/Alfa Romeo.png'
import porsche from '../images/HomeMob/germanMob/Porsche.png'
import mclaren from '../images/HomeMob/germanMob/McLaren.png'
import byd from '../images/HomeMob/chineseMob/BYD.png'
import changan from '../images/HomeMob/chineseMob/Changan.png'
import Hongqi from '../images/HomeMob/chineseMob/Hongqi.png'
import geely from '../images/HomeMob/chineseMob/Geely.png'
import gac from '../images/HomeMob/chineseMob/GAC.png'
import saic from '../images/HomeMob/chineseMob/SAIC Motor.png'
import Hyundai from '../images/HomeMob/chineseMob/Hyundai.png'
import kia from '../images/HomeMob/chineseMob/Kia.png'
import SsangYong from '../images/HomeMob/chineseMob/SsangYong.png'
import Genesis from '../images/HomeMob/chineseMob/Genesis.png'
import daewoo from '../images/HomeMob/chineseMob/Daewoo.png'
import renault from '../images/HomeMob/chineseMob/renault.png'
import Peugeot from '../images/HomeMob/chineseMob/Peugeot.png'
import alpina from '../images/HomeMob/chineseMob/Alpina.png'
import Bugatti from '../images/HomeMob/chineseMob/Bugatti.png'
import Citroen from '../images/HomeMob/chineseMob/Citroen.png'
import DS from '../images/HomeMob/chineseMob/DS.png'
import arrow from '../images/HomeMob/Arrow 1.png'
import NavbarMob from './NavbarMob'
import FooterMob from './FooterMob'
import Support from './Support'




function HomeMob() {
    const [selectedBrand, setSelectedBrand] = useState('Japanese');
    const [selectedBrandTwo, setSelectedBrandTwo] = useState('Chinese');
    const navigate = useNavigate();

    const handleBrandClick = (brand) => {
        setSelectedBrand(brand);
    };

    const handleBrand2Click = (brand) => {
        setSelectedBrandTwo(brand);
    };

    const handleAMPOpenClick = () => {
        navigate('/amp');
      };

      const [showSupport, setShowSupport] = useState(false);

      const toggleSupport = () => {
        setShowSupport(!showSupport);
      };
      
    return (
        <div className='min-h-screen flex flex-col'>
            <NavbarMob/>
            <div className='w-full h-[401px] bg-[black]  relative flex flex-row'>
                <img src={homebg} alt='homebg' style={{ width: '100%', height: '100%' }} />
                <div className='absolute w-[100%] h-[100%] flex justify-center items-center '>
                    <img src={bgcar} alt='bgcar' />

                </div>
                <div className='homeMainImgSupport'>
          <img className='supportAnimation'  src={support} alt='support' onClick={toggleSupport} />
          {showSupport && <Support onClose={toggleSupport} />}

        </div>
            </div>
            <div className='w-full h-auto bg-[#f2f2f2]  flex flex-col '>
                <div className='w-full pt-12 gap-3 px-12 flex flex-col justify-center items-center'>
                    <span className='w-full font-[700] text-xl font-[display]'>Auto Spare Parts Wholesale</span>
                    <span className='w-[90%] font-[400] text-sm font-[display]'>Upgrade Your Vehicle's Performance with Genuine Parts!</span>

                </div>
                <div className='w-full pt-12 gap-12 px-8 flex flex-col justify-center items-center '>

                    <div className='w-full flex flex-row justify-between  gap-8'>
                        <div className='flex flex-col  w-full  gap-2  items-center'>
                            <div className='h-[55px] w-[70%] rounded-full bg-[white] flex justify-center items-center'>
                                <img src={search} alt='search' style={{ width: '50%', height: '50%' }} />
                            </div>
                            <span className=' font-[600] text-xs font-[display]'>Transparent and safe Business</span>
                        </div>
                        <div className='flex flex-col  w-full  gap-2  items-center'>
                            <div className='h-[55px]  w-[70%] rounded-full bg-[white] flex justify-center items-center'>
                                <img src={time} alt='time' style={{ width: '50%', height: '50%' }} />

                            </div>
                            <span className=' font-[600] text-xs font-[display]'>Fast and Accurate order Processing</span>
                        </div>
                        <div className='flex flex-col  w-full  gap-2  items-center'>
                            <div className='h-[55px]  w-[70%] rounded-full bg-[white] flex justify-center items-center'>
                                <img src={analysis} alt='analysis' style={{ width: '50%', height: '50%' }} />

                            </div>
                            <span className=' font-[600] text-xs font-[display]'>Flexible Client Service</span>
                        </div>

                    </div>
                    <div className='w-[63%] flex flex-row justify-between  gap-8'>
                        <div className='flex flex-col  w-full  gap-2  items-center'>
                            <div className='h-[55px] w-[70%] rounded-full bg-[white] flex justify-center items-center'>
                                <img src={payment} alt='payment' style={{ width: '50%', height: '50%' }} />
                            </div>
                            <span className=' font-[600] text-xs font-[display]'>Convenient payment methods</span>
                        </div>
                        {/* <div className='flex flex-col  w-full  gap-2  items-center'>
                            <div className='h-[55px]  w-[70%] rounded-full bg-[white] flex justify-center items-center'>
                                <img src={time} alt='time' style={{width:'50%',height:'50%'}} />

                            </div>
                            <span className=' font-[600] text-xs font-[display]'>Fast and Accurate order Processing</span>
                        </div> */}
                        <div className='flex flex-col  w-full  gap-2  items-center'>
                            <div className='h-[55px]  w-[70%] rounded-full bg-[white] flex justify-center items-center'>
                                <img src={guarantee} alt='guarantee' style={{ width: '50%', height: '50%' }} />

                            </div>
                            <span className=' font-[600] text-xs font-[display]'>Safety and Quality gurantee</span>
                        </div>

                    </div>

                </div>
                <div className='flex flex-col w-full h-auto gap-8 px-3 justify-center items-center'>
                    <span className='pt-12 text-base font-[600] font-display'>Why FAZCO?</span>
                    <span className='text-sm font-[400] font-display'>Fazco Auo Parts Trading" is one of the  company in Leading Automotive Genuine
                        & Aftermarket spare parts. We supply auto
                        spare parts for Japanese, and Korean,American cars.
                        Our range of products includes Genuine and duplicate spare parts for passenger  vehicles.</span>
                    <img src={ring} alt='ring' style={{ width: '50%', height: '50%' }} />

                </div>

                <div className='flex flex-col w-full h-auto gap-8 px-3 justify-center items-center'>
                    <div className='pt-12 flex flex-col gap-3  w-full justify-center items-center'>
                        <div className='w-full flex flex-row gap-3 justify-center items-center'>

                            <div className={`w-[50%] h-[29px]  border-2 border-[#BFBFBF] rounded-[5px] flex justify-center items-center text-sm font-[600] font-display ${selectedBrand === 'Japanese' ? 'active' : ''}`} onClick={() => handleBrandClick('Japanese')}>Japanese Brand</div>
                            <div className={`w-[50%] h-[29px] border-2 border-[#BFBFBF] rounded-[5px] flex justify-center items-center text-sm font-[600] font-display ${selectedBrand === 'American' ? 'active' : ''}`} onClick={() => handleBrandClick('American')}>American Brand</div>
                        </div>
                        <div className='w-full flex justify-center items-center'>
                            <div className={`w-[50%] h-[29px]  border-2 border-[#BFBFBF] rounded-[5px] flex justify-center items-center text-sm font-[600] font-display ${selectedBrand === 'German' ? 'active' : ''}`} onClick={() => handleBrandClick('German')}>German & European</div>
                        </div>
                    </div>
                    <div className='w-full flex justify-center items-center gap-3'>
                        <div className='grid grid-cols-3 w-full gap-3' style={{ display: selectedBrand === 'Japanese' ? 'grid' : 'none' }}>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={suzuki} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={toyota} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={nissan} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={honda} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={mazda} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={subaru} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={daihatsu} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={mitsubishi} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={hino} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={isuzu} alt='suzuki' />
                            </div>


                        </div>

                        <div className='grid grid-cols-3 w-full gap-3' style={{ display: selectedBrand === 'American' ? 'grid' : 'none' }}>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={chevrolet} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={ford} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={gm} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={cadilacc} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={dodge} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={chrysler} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={buick} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={tesla} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={gmc} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={jeep} alt='suzuki' />
                            </div>




                        </div>

                        <div className='grid grid-cols-3 w-full gap-3' style={{ display: selectedBrand === 'German' ? 'grid' : 'none' }}>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={benz} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={audi} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={bmw} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={volkswagen} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={skoda} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={jaguar} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={mg} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={seat} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={bentley} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={opel} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={aston} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={mini} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={landrover} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={lamborghini} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={rollsroyce} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={alfa} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={porsche} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={mclaren} alt='suzuki' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col w-full h-auto gap-8 px-3 justify-center items-center'>
                    <div className='pt-12 flex flex-col gap-3  w-full justify-center items-center'>
                        <div className='w-full flex flex-row gap-3 justify-center items-center'>

                            <div className={`w-[50%] h-[29px]  border-2 border-[#BFBFBF] rounded-[5px] flex justify-center items-center text-sm font-[600] font-display ${selectedBrandTwo === 'Chinese' ? 'active' : ''}`} onClick={() => handleBrand2Click('Chinese')}>Chinese Brand</div>
                            <div className={`w-[50%] h-[29px] border-2 border-[#BFBFBF] rounded-[5px] flex justify-center items-center text-sm font-[600] font-display ${selectedBrandTwo === 'Korean' ? 'active' : ''}`} onClick={() => handleBrand2Click('Korean')}>Korean Brand</div>
                        </div>
                        <div className='w-full flex justify-center items-center'>
                            <div className={`w-[50%] h-[29px]  border-2 border-[#BFBFBF] rounded-[5px] flex justify-center items-center text-sm font-[600] font-display ${selectedBrandTwo === 'French' ? 'active' : ''}`} onClick={() => handleBrand2Click('French')}>French Brand</div>
                        </div>
                    </div>
                    <div className='w-full flex justify-center items-center gap-3'>
                        <div className='grid grid-cols-3 w-full gap-3' style={{ display: selectedBrandTwo === 'Chinese' ? 'grid' : 'none' }}>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={byd} alt='suzuki' /> 
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={changan} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={Hongqi} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={geely} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={gac} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={saic} alt='suzuki' />
                            </div>



                        </div>

                        <div className='grid grid-cols-3 w-full gap-3' style={{ display: selectedBrandTwo === 'Korean' ? 'grid' : 'none' }}>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={Hyundai} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={kia} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={SsangYong} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={Genesis} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={daewoo} alt='suzuki' />
                            </div>



                        </div>

                        <div className='grid grid-cols-3 w-full gap-3' style={{ display: selectedBrandTwo === 'French' ? 'grid' : 'none' }}>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={renault} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={Peugeot} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={alpina} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={Bugatti} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={Citroen} alt='suzuki' />
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center'>
                                <img src={DS} alt='suzuki' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col w-full py-12 h-auto gap-8 px-3 justify-center items-center'>
                    <div className='w-full flex flex-row justify-between '>
                        <div className='text-base font-[600] font-display'>After Market Products</div>
                        {/* <div className='flex flex-row gap-1'> */}
                        <span className='text-base font-[400] font-display text-[#AAAAAA] underline' onClick={handleAMPOpenClick}>See All</span>
                        {/* <img src={arrow} alt='arrow' /> */}
                        {/* </div> */}
                    </div>
                    <div className='w-full flex justify-center items-center gap-3'>
                        <div className='grid grid-cols-3 w-full gap-3'>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                FEBEST
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                CFA FRANCE
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                BOSCH
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                FEBI BILSTEIN
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                DAYCO
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                RENKEN
                            </div>
                       
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                VALEO
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                NSK
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                KASHIYAMA
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                HIQ
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                DAWOON
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                HELLA
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                KAYABA
                            </div>
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                NGK
                            </div>
                         
                            <div className='h-[53px] border-2 border-[#BFBFBF] rounded-[3px] flex justify-center items-center text-sm font-[600] font-display'>
                                HDK
                            </div>



                        </div>
                    </div>
                </div>


            </div>
            <FooterMob/>
        </div>
    )
}

export default HomeMob