import React,{useState,useEffect} from 'react'
import Navbar from './Navbar'
import NavbarMob from './NavbarMob';

function Customers() {

  const [customers, setCustomers] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
      // Update the screen size on resize
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };

      // Attach event listener
      window.addEventListener('resize', handleResize);

      // Clean up the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${apiBaseUrl}/user`)
      .then(response => response.json())
      .then(data => {
        // Filter customers with customerType as "customer"
        const filteredCustomers = data.filter(customer => customer.customerType === 'customer');
        setCustomers(filteredCustomers);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [apiBaseUrl]);

  const reversedCustomers = [...customers].reverse();

  return (
    <div className='min-h-screen flex flex-col bg-[#e4e2e2]'>
      <div className='Navbar'>
      {isMobile ? <NavbarMob /> : <Navbar />}
      </div>
      <div className='w-full  flex justify-center items-center px-3 py-12'>
        <div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 w-full gap-3'>
        {reversedCustomers.map(customer => (
          <div className='h-[300px]  rounded-[5px] flex flex-col'>
            <div className='w-full h-[20%] bg-[#FF9A00] flex justify-start items-center px-3 text-base font-[700] font-display'>{customer.username}</div>
            <div className='w-full h-[80%] bg-[white] flex flex-col px-3 py-12 gap-2 '>
           
              <div className='flex justify-between items-center'>
                <span className='text-base font-[500] font-display'>Email</span>
                <span className='text-base font-[500] font-display'>:</span>
                <span className='text-base font-[400] font-display'>{customer.email}</span>
              </div>
              <div className='flex justify-between items-center'>
                <span className='text-base font-[500] font-display'>Address 1</span>
                <span className='text-base font-[500] font-display'>:</span>
                <span className='text-base font-[400] font-display'>{customer.address1}</span>
              </div>
              <div className='flex justify-between items-center'>
                <span className='text-base font-[500] font-display'>Address 2</span>
                <span className='text-base font-[500] font-display'>:</span>
                <span className='text-base font-[400] font-display'>{customer.address2}</span>
              </div>
              <div className='flex justify-between items-center'>
                <span className='text-base font-[500] font-display'>Mobile Number</span>
                <span className='text-base font-[500] font-display'>:</span>
                <span className='text-base font-[400] font-display'>{customer.mobile}</span>
              </div>

            </div>

          </div>
            ))}
       
          
         

        </div>
      </div>
    </div>
  )
}

export default Customers