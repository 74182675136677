import React, { useState, useEffect, useRef } from 'react';
import './PriceUpload.css';
import Navbar from './Navbar';
import dropdown from '../images/dropdown.png';
import Drawer from './Drawer';
import ComboBox from 'shersoft-combov1';
import NavbarMob from './NavbarMob';

function PriceUpdate() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        // Update the screen size on resize
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Attach event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [currentDate, setCurrentDate] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [focusedInputIndex, setFocusedInputIndex] = useState(null);
    const inputRefs = useRef([]);
    const [selectedPartCode, setSelectedPartCode] = useState(''); // State to keep track of selected part code
    const [options, setOptions] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const toggleDrawer = () => {
        setIsDrawerOpen(prevState => !prevState);
    };
    const [formData, setFormData] = useState({
        pu_id: '',
        NAME: '',
        MODEL: '',
        BRAND: '',
        PRICE: '',
        QTY: '',
        DELIVERYTIME: '',
        WEIGHT: '',
        SUPPLIERNAME: '',
        UPLOADDATE: '',
        SUPPLIERCOST: '',
        MANUFACTURER:''
    });

    // console.log(formData);

    useEffect(() => {
        const getCurrentDate = () => {
            const dateObj = new Date();
            const day = String(dateObj.getDate()).padStart(2, '0');
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero based
            const year = dateObj.getFullYear();
            return `${day}-${month}-${year}`;
        };

        setCurrentDate(getCurrentDate());
        // Fetch options from API
        fetchOptions();
    }, []);

    
    useEffect(() => {
        // Fetch and populate form data when selected part code changes
        if (selectedPartCode) {
            fetchFormData(selectedPartCode);
        }
    }, [selectedPartCode]);

    const fetchFormData = async (partCode) => {
        try {
            const response = await fetch(`${apiBaseUrl}/priceupload/${partCode}`);
            const data = await response.json();
            setFormData(data);
        } catch (error) {
            console.error('Error fetching form data:', error);
        }
    };


    const fetchOptions = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/priceuploadget`);
            const data = await response.json();
            setOptions(data.map(option => option.PARTCODE)); // Extract PARTCODE from each option
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    // console.log(options);

    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleInputFocus = (index) => {
        setFocusedInputIndex(index);
    };

    const handlePartCodeChange = async (e) => {
        const selectedPartCode = e.target.value;
        setSelectedPartCode(selectedPartCode);
    
        try {
            const response = await fetch(`${apiBaseUrl}/priceupload/${selectedPartCode}`);
            const data = await response.json();
            setFormData(data); // Update state with retrieved data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    
        // Find the next input field index
        const nextIndex = inputRefs.current.findIndex((ref, index) => index > focusedInputIndex && ref);
    
        // If a valid next index is found, focus on that input field
        if (nextIndex !== -1) {
            inputRefs.current[nextIndex].focus();
            setFocusedInputIndex(nextIndex);
        }
    };
    
    // console.log(selectedPartCode);


    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: value
        }));
    };

    const submitForm = () => {
        const updatedFormData = {
            ...formData,
            UPLOADDATE: currentDate
        };
    
        console.log(updatedFormData);
    
        const isFormFilled = Object.values(updatedFormData).every(value => value !== '');
    
        if (isFormFilled) {
            fetch(`${apiBaseUrl}/priceupload/${updatedFormData.PARTCODE}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedFormData)
            })
            .then(response => {
                if (response.ok) {
                    // Reset form data
                    setFormData({
                        pu_id: '',
                        NAME: '',
                        MODEL: '',
                        BRAND: '',
                        PRICE: '',
                        QTY: '',
                        DELIVERYTIME: '',
                        WEIGHT: '',
                        SUPPLIERNAME: '',
                        UPLOADDATE: '',
                        SUPPLIERCOST: '',
                        MANUFACTURER:''
                    });
                    
                    // Reload the page
                    window.location.reload();
                    
                    return response.text();
                }
                throw new Error('Form data update failed');
            })
            .then(data => {
                alert('Form updated successfully');
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } else {
            alert('Please fill in all fields before updating.');
        }
    };
    
    

    useEffect(() => {
        // Focus on the first input field when component mounts
        inputRefs.current[0].focus();
    }, []); 


    return (
        <div className='mainDiv'>
            <div className='Navbar'>
            {isMobile ? <NavbarMob /> : <Navbar />}
            </div>
            <div className='topBar'>
                <div className='topBarSub'>
                    <div className='topBarSubImg' onClick={toggleDrawer}>
                        <img className='topBarSubImgPic' alt='dropdown' src={dropdown} />
                    </div>
                    <div className='topBarSubHead'>PRICE UPDATE</div>
                    <div className='topBarSubHeadFree'></div>
                </div>
            </div>
            <div className='priceUploadMainDiv'>
                {isDrawerOpen && <Drawer isOpen={isDrawerOpen} />}
                <div className='priceUploadDivBorder'>
                    <div className='priceUploadDivBorderRow1'>
                        <div className='priceUploadDivBorderCol1'>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>PART CODE</label>
                                <div className={`custom-dropdown ${focusedInputIndex === 0 ? 'focused' : ''}`}>
                                    <ComboBox
                                    className="priceUploadDivBorderCol1InputCombobox2"
                                    onInputChange={(value) => handlePartCodeChange({ target: { value } })} 
                                        comboRef={el => (inputRefs.current[0] = el)}
                                        options={options}
                                        inputClassName="priceUploadDivBorderCol1InputCombobox"
                                        onKeyDown={e => handleKeyDown(e, 0)}

                                    />
                                </div>
                            </div>

                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>MODEL</label>
                                <input
                                    ref={el => (inputRefs.current[2] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 2 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 2)}
                                    onFocus={() => handleInputFocus(2)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'MODEL')}
                                    value={formData.MODEL}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>PRICE</label>
                                <input
                                    type='number'

                                    ref={el => (inputRefs.current[4] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 4 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 4)}
                                    onFocus={() => handleInputFocus(4)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'PRICE')}
                                    value={formData.PRICE}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>DELIVERY TIME</label>
                                <input
                                    ref={el => (inputRefs.current[6] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 6 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 6)}
                                    onFocus={() => handleInputFocus(6)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'DELIVERYTIME')}
                                    value={formData.DELIVERYTIME}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>SUPPLIER NAME</label>
                                <input
                                    ref={el => (inputRefs.current[8] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 8 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 8)}
                                    onFocus={() => handleInputFocus(8)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'SUPPLIERNAME')}
                                    value={formData.SUPPLIERNAME}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>UPLOAD DATE</label>
                                <input
                                    className='priceUploadDivBorderCol1Input'
                                    value={currentDate}
                                    readOnly
                                />
                            </div>
                            <div className='priceUploadDivBorderColContentButton' onClick={submitForm}>SUBMIT</div>
                        </div>
                    </div>
                    <div className='priceUploadDivBorderRow2'>
                        <div className='priceUploadDivBorderCol2'>
                           
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>NAME</label>
                                <input
                                    ref={el => (inputRefs.current[1] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 1 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 1)}
                                    onFocus={() => handleInputFocus(1)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'NAME')}
                                    value={formData.NAME}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>BRAND</label>
                                <input
                                    ref={el => (inputRefs.current[3] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 3 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 3)}
                                    onFocus={() => handleInputFocus(3)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'BRAND')}
                                    value={formData.BRAND}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>QTY</label>
                                <input
                                    type='number'

                                    ref={el => (inputRefs.current[5] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 5 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 5)}
                                    onFocus={() => handleInputFocus(5)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'QTY')}
                                    value={formData.QTY}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>WEIGHT</label>
                                <input


                                    ref={el => (inputRefs.current[7] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 7 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e, 7)}
                                    onFocus={() => handleInputFocus(7)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'WEIGHT')}
                                    value={formData.WEIGHT}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>SUPPLIER COST</label>
                                <input
                                    type='number'

                                    ref={el => (inputRefs.current[9] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 9 ? 'focused' : ''}`}
                                    onKeyDown={e => handleKeyDown(e)}
                                    onFocus={() => handleInputFocus(9)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'SUPPLIERCOST')}
                                    value={formData.SUPPLIERCOST}
                                />
                            </div>
                            <div className='priceUploadDivBorderColContent'>
                                <label className='priceUploadDivBorderCol1Label'>MANUFACTURE</label>
                                <input
                                    

                                    ref={el => (inputRefs.current[10] = el)}
                                    className={`priceUploadDivBorderCol1Input ${focusedInputIndex === 10 ? 'focused' : ''}`}                                    
                                    onKeyDown={e => handleKeyDown(e, 10)}
                                    onFocus={() => handleInputFocus(10)}
                                    onBlur={() => setFocusedInputIndex(null)}
                                    onChange={e => handleInputChange(e, 'MANUFACTURER')}
                                    value={formData.MANUFACTURER}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PriceUpdate