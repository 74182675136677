import React, { useState, useEffect } from 'react'
import './Table.css'
import Navbar from './Navbar';
import dropdown from '../images/dropdown.png';
import glass from '../images/Magnifying glass.png'
import bin from '../images/Bin.png'
import { useNavigate } from 'react-router-dom';
import Drawer from './Drawer';

function Table() {

    const [tableData, setTableData] = useState([]);
    const [subTableData, setSubTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [subFilteredData, setSubFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [countMap, setCountMap] = useState({}); 
    const [countMap2, setCountMap2] = useState({}); 

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const navigate = useNavigate();

    const isActive = sessionStorage.getItem('isActive');
    const customerType = sessionStorage.getItem('customerType');


    // console.log(customerType);
    // console.log(isActive);

    useEffect(() => {
        // Redirect to login page if isActive is null
        if (isActive === null) {
            navigate('/login');
        }
    }, [isActive, navigate]);



    const toggleDrawer = () => {
        setIsDrawerOpen(prevState => !prevState);
    };

    useEffect(() => {
        fetchData();
        fetchSubData();
    }, []);

    useEffect(() => {
        setFilteredData(
            tableData.filter(
                item =>
                    item.PARTCODE.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.NAME.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );

        setSubFilteredData(
            subTableData.filter(
                subItem =>
                    subItem.SUBPARTCODE.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    subItem.NAME.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, tableData, subTableData]);

  
    const handleSearch = event => {
        setSearchQuery(event.target.value);
    };


    const fetchData = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/priceuploadget`);
            const data = await response.json();
            setTableData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchSubData = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/subpriceget`);
            const data = await response.json();
            setSubTableData(data);
        } catch (error) {
            console.error('Error fetching sub data:', error);
        }
    };

    const handleDelete = async PARTCODE => {
        try {
            const response = await fetch(`${apiBaseUrl}/deleteProperty/${PARTCODE}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setTableData(tableData.filter(item => item.PARTCODE !== PARTCODE));           
            } else {
                console.error('Failed to delete item.');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };


    const handleSubDelete = async SUBPARTCODE => {
        try {
            const response = await fetch(`${apiBaseUrl}/deletesubpartcode/${SUBPARTCODE}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setSubTableData(subTableData.filter(subItem => subItem.SUBPARTCODE !== SUBPARTCODE));
            } else {
                console.error('Failed to delete item.');
            }
        } catch (error) {
            console.error('Error deleting item:', error);   
        }
    };



    const handleSignUpOpenClick = () => {
        navigate('/signup');
    };

    const updateCount = (partCode, operation) => {
        setCountMap(prevCountMap => {
            const newCount = operation === '+' ? (prevCountMap[partCode] || 0) + 1 : Math.max((prevCountMap[partCode] || 0) - 1, 0);
            const updatedCountMap = { ...prevCountMap, [partCode]: newCount };

            // Save to local storage
            const dataToSave = tableData.find(item => item.PARTCODE === partCode);
            if (dataToSave) {
                const savedData = { ...dataToSave, qty: newCount };
                localStorage.setItem(`row-${partCode}`, JSON.stringify(savedData));
            }

            return updatedCountMap;
        });
    };
    

    const updateCount2 = (subpartCode, operation) => {
        setCountMap2(prevCountMap => {
            const newCount = operation === '+' ? (prevCountMap[subpartCode] || 0) + 1 : Math.max((prevCountMap[subpartCode] || 0) - 1, 0);
            const updatedCountMap = { ...prevCountMap, [subpartCode]: newCount };

            // Save to local storage
            const dataToSave = subTableData.find(subItem => subItem.SUBPARTCODE === subpartCode);
            if (dataToSave) {
                const savedData = { ...dataToSave, qty: newCount };
                localStorage.setItem(`row2-${subpartCode}`, JSON.stringify(savedData));
            }

            return updatedCountMap;
        });
    };

    const handleSendEmail = () => {
        // Retrieve saved data from local storage
        const emailBody = Object.keys(localStorage)
            .filter(key => key.startsWith('row-') || key.startsWith('row2-'))
            .map(key => {
                const item = JSON.parse(localStorage.getItem(key));

                // Determine if it's `row-` or `row2-` data and format accordingly
                const prefix = key.startsWith('row2-') ? 'SUBPARTCODE' : 'PARTCODE';
                return `${item[prefix]} \n Name: ${item.NAME}, Model: ${item.MODEL}, Brand: ${item.BRAND}, Price: ${item.PRICE.toFixed(2)}, QTY: ${item.QTY}, Delivery Time: ${item.DELIVERYTIME}, Weight: ${item.WEIGHT},\n Total Count: ${item.qty}\n\n`;
            })
            .join('');

        // Prepare email details    
        const recipientEmail = 'fazcoautoparts@gmail.com';
        const subject = 'New Order';

        // Open default email client with pre-filled email template
        window.open(`mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`);

        // Optionally, clear local storage after sending the email
        Object.keys(localStorage)
            .filter(key => key.startsWith('row-') || key.startsWith('row2-'))   
            .forEach(key => localStorage.removeItem(key));

        window.location.reload();
    };

    const hasLocalStorageItems = Object.keys(localStorage).filter(key => key.startsWith('row-')).length > 0;
    const hasLocalStorageItems2 = Object.keys(localStorage).filter(key => key.startsWith('row2-')).length > 0;

    return (
        <div className='mainDiv2'>
            <div className='Navbar'>
                <Navbar />
            </div>
            <div className='tableTopBar'>
                <div className='tableTopBarSub'>
                    <div className='tableTopBarSubImg' >
                        {customerType !== 'customer' && (
                            <img onClick={toggleDrawer} className='tableTopBarSubImgPic' alt='dropdown' src={dropdown} />
                        )}

                        {(hasLocalStorageItems || hasLocalStorageItems2) && customerType === 'customer' && (
                            <div className='tableMailDiv'>
                                <div className='tableMainDivButton' onClick={handleSendEmail}>Place Order</div>
                            </div>
                        )}

                    </div>
                    <div className='tableSearchDiv'>
                        <input placeholder='Enter the Part Code or Name...' className='tableSearchDivInput' value={searchQuery}
                            onChange={handleSearch} />
                    </div>
                    <div className='tableButtonDiv'>

                        <div className='tableButtonDivSub' >
                            <img src={glass} alt='glass' />
                            <span className='tableButtonDivSubSpan'>Search</span>
                        </div>
                        {customerType !== 'customer' && (<div className='tableSignUp' onClick={handleSignUpOpenClick}>Sign Up?</div>)}
                    </div>

                </div>
            </div>
            <div className='tablePageMainDiv'>
                {isDrawerOpen && <Drawer isOpen={isDrawerOpen} />}
                <div className='tablePageMainDivSub '>
                    {filteredData.length > 0 && ( // Check if there is data to display
                        <table className='tableTable'>
                            <thead className='tablePageHead'>
                                <tr className='tablePageTr'>
                                    <th className='tablePartCode border-r-2 border-[white]'>Part Code</th>
                                    <th className='tableName border-r-2 border-[white]'>Name</th>
                                    <th className='tableModel border-r-2 border-[white]'>Model</th>
                                    <th className='tableBrand border-r-2 border-[white]'>Brand</th>
                                    <th className='tablePrice border-r-2 border-[white]'>Price</th>
                                    <th className='tableQty border-r-2 border-[white]'>Qty</th>
                                    <th className='tableDelivery border-r-2 border-[white]'>Delivery Time</th>
                                    <th className='tableWeight border-r-2 border-[white]'>Weight</th>
                                    <th className='tableWeight border-r-2 border-[white]'>Manufacture</th>
                                    {customerType !== 'customer' && (<th className='tableSupplierName border-r-2 border-[white]'>Supplier Name</th>)}
                                    {customerType !== 'customer' && (<th className='tableSupplierCost border-r-2 border-[white]'>Supplier Cost</th>)}
                                    {customerType !== 'customer' && (<th className='tableUploadDate border-r-2 border-[white]'>Upload Date</th>)}
                                    {customerType !== 'customer' && (<th style={{ width: '7%' }} className='tableDelete'></th>)}
                                    {customerType === 'customer' && (<th className='tableCount'></th>)}



                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        className={index % 2 === 0 ? 'tableEvenRow' : 'tableOddRow'}
                                    >
                                        <td className='border-r-2 border-[white]'>{item.PARTCODE}</td>
                                        <td className='border-r-2 border-[white]'>{item.NAME}</td>
                                        <td className='border-r-2 border-[white]'>{item.MODEL}</td>
                                        <td className='border-r-2 border-[white]'>{item.BRAND}</td>
                                        <td className='border-r-2 border-[white]' >{item.PRICE.toFixed(2)}</td>
                                        <td className='border-r-2 border-[white]'>{item.QTY}</td>
                                        <td className='border-r-2 border-[white]'>{item.DELIVERYTIME}</td>
                                        <td className='border-r-2 border-[white]'>{item.WEIGHT}</td>
                                        <td className='border-r-2 border-[white]'>{item.MANUFACTURER}</td>
                                        {customerType !== 'customer' && (<td className='border-r-2 border-[white]'>{item.SUPPLIERNAME}</td>)}
                                        {customerType !== 'customer' && (<td className='border-r-2 border-[white]'>{item.SUPPLIERCOST.toFixed(2)}</td>)}
                                        {customerType !== 'customer' && (<td className='border-r-2 border-[white]'>{item.UPLOADDATE}</td>)}
                                        {customerType !== 'customer' && (<td style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                            <img
                                                style={{ width: '15%', height: '15%', cursor: 'pointer', marginTop: '10%' }}
                                                alt='delete'
                                                src={bin}
                                                className='deleteicon'
                                                onClick={() => handleDelete(item.PARTCODE)}
                                            />                                   </td>)}
                                        {customerType === 'customer' && (
                                            <td className='px-1'>
                                                <div className="quantity-container">
                                                    <div
                                                        className='border-2 border-[black] bg-[#e1e0e0] w-[2vw] flex justify-center items-center cursor-pointer'
                                                        onClick={() => updateCount(item.PARTCODE, '-')}
                                                    >
                                                        -
                                                    </div>
                                                    <span>{countMap[item.PARTCODE] || 0}</span>
                                                    <div
                                                        className='border-2 border-[black] bg-[#e1e0e0] w-[2vw] flex justify-center items-center cursor-pointer'
                                                        onClick={() => updateCount(item.PARTCODE, '+')}
                                                    >
                                                        +
                                                    </div>
                                                </div>
                                            </td>
                                        )}


                                    </tr>


                                ))}

                            </tbody>



                        </table>

                    )}

                </div>

            </div>
            {searchQuery && subFilteredData.length > 0 && (
                <div className='tablePageMainDivSub'>
                    <table className='tableTable'>
                        <thead className='tablePageHead'>
                            <tr className='tablePageTr'>
                                <th className='tablePartCode border-r-2 border-[white]'>Subpart Code</th>
                                <th className='tableName border-r-2 border-[white]'>Name</th>
                                <th className='tableModel border-r-2 border-[white]'>Model</th>
                                <th className='tableBrand border-r-2 border-[white]'>Brand</th>
                                <th className='tablePrice border-r-2 border-[white]'>Price</th>
                                <th className='tableQty border-r-2 border-[white]'>Qty</th>
                                <th className='tableDelivery border-r-2 border-[white]'>Delivery Time</th>
                                <th className='tableWeight border-r-2 border-[white]'>Weight</th>
                                <th className='tableWeight border-r-2 border-[white]'>Manufacture</th>
                                {customerType !== 'customer' && (<th className='tableSupplierName border-r-2 border-[white]'>Supplier Name</th>)}
                                {customerType !== 'customer' && (<th className='tableSupplierCost border-r-2 border-[white]'>Supplier Cost</th>)}
                                {customerType !== 'customer' && (<th className='tableUploadDate border-r-2 border-[white]'>Upload Date</th>)}
                                {customerType !== 'customer' && (<th style={{ width: '7%' }} className='tableDelete'></th>)}
                                {customerType === 'customer' && (<th className='tableCount'></th>)}


                            </tr>
                        </thead>
                        <tbody>
                            {subFilteredData.map((subItem, index) => (
                                <tr
                                    className={index % 2 === 0 ? 'tableEvenRow' : 'tableOddRow'}
                                    key={subItem.id}
                                >
                                    <td className='border-r-2 border-[white]'>{subItem.SUBPARTCODE}</td>
                                    <td className='border-r-2 border-[white]'>{subItem.NAME}</td>
                                    <td className='border-r-2 border-[white]'>{subItem.MODEL}</td>
                                    <td className='border-r-2 border-[white]'>{subItem.BRAND}</td>
                                    <td className='border-r-2 border-[white]'>{subItem.PRICE.toFixed(2)}</td>
                                    <td className='border-r-2 border-[white]'>{subItem.QTY}</td>
                                    <td className='border-r-2 border-[white]'>{subItem.DELIVERYTIME}</td>
                                    <td className='border-r-2 border-[white]'>{subItem.WEIGHT}</td>
                                    <td className='border-r-2 border-[white]'>{subItem.MANUFACTURER}</td>
                                    {customerType !== 'customer' && (<td className='border-r-2 border-[white]'>{subItem.SUPPLIERNAME}</td>)}
                                    {customerType !== 'customer' && (<td className='border-r-2 border-[white]'>{subItem.SUPPLIERCOST.toFixed(2)}</td>)}
                                    {customerType !== 'customer' && (<td className='border-r-2 border-[white]'>{subItem.UPLOADDATE}</td>)}
                                    {customerType !== 'customer' && (<td style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                        <img
                                            style={{ width: '15%', height: '15%', cursor: 'pointer', marginTop: '10%' }}
                                            alt='delete'
                                            src={bin}
                                            onClick={() => handleSubDelete(subItem.SUBPARTCODE)}
                                        />                                   </td>)}
                                    {customerType === 'customer' && (
                                        <td className='px-1'>
                                            <div className="quantity-container">
                                                <div
                                                    className='border-2 border-[black] bg-[#e1e0e0] w-[2vw] flex justify-center items-center cursor-pointer'
                                                    onClick={() => updateCount2(subItem.SUBPARTCODE, '-')}
                                                >
                                                    -
                                                </div>
                                                <span>{countMap2[subItem.SUBPARTCODE] || 0}</span>
                                                <div
                                                    className='border-2 border-[black] bg-[#e1e0e0] w-[2vw] flex justify-center items-center cursor-pointer'
                                                    onClick={() => updateCount2(subItem.SUBPARTCODE, '+')}
                                                >
                                                    +
                                                </div>
                                            </div>
                                        </td>
                                    )}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

        </div>
    )
}

export default Table