import React from 'react';
import './Support.css';
import logo from '../images/newlogo.png';

function Support({ onClose }) {
    const handleClose = () => {
        onClose(); // Call the onClose callback provided by the parent component
    };

    const handleWhatsAppClick = () => {
        const vinOrFrame = document.querySelector('.SupportFillDivRow1Input[name="vinOrFrame"]').value;
        const carName = document.querySelector('.SupportFillDivRow1Input[name="carName"]').value;
        const carModel = document.querySelector('.SupportFillDivRow1Input[name="carModel"]').value;
        const brand = document.querySelector('.SupportFillDivRow1Input[name="brand"]').value;
        const message = document.querySelector('.SupportFillDivRow2Input[name="message"]').value;
        const whatsapp = `VIN OR FRAME: ${vinOrFrame}\nCAR NAME: ${carName}\nCAR MODEL: ${carModel}\nBRAND: ${brand}\nMESSAGE: ${message}`;

        // Format the message for WhatsApp
        const encodedMessage = encodeURIComponent(whatsapp);

        // Open WhatsApp with the message
        window.open(`https://wa.me/?text=${encodedMessage}`);
    };

    return (
        <div className='supportMainDiv '>
            <div className='supportMainDivSub'>
                <div></div>
                <div className='supportMainHead'>

                    <div className='supportMainHeadImg'>
                        <img src={logo} alt='logo' style={{ height: '100%', width: '100%' }} />
                    </div>
                    <span className='supportMainHeadSpan'>FAZCO</span>
                </div>
                <span className='supportMainSubHead'>If You do not remember the Part code, then fill in the parameters below and send to +971 54 347 6417
                </span>
                <span className='supportMainColorSub'>
                    Example of the Correct VIN XW8AN2NE3JH035743
                </span>
                <div className='SupportFillDiv'>
                    <div className='SupportFillDivRow1'>
                        <span className='SupportFillDivRow1Span'>
                            VIN OR FRAME
                        </span>
                        <input className='SupportFillDivRow1Input border-2 border-[#848383]' name="vinOrFrame" />
                    </div>
                    <div className='SupportFillDivRow1'>
                        <span className='SupportFillDivRow1Span'>
                            CAR NAME
                        </span>
                        <input className='SupportFillDivRow1Input border-2 border-[#848383]' name="carName" />
                    </div>
                    <div className='SupportFillDivRow1'>
                        <span className='SupportFillDivRow1Span'>
                            CAR MODEL
                        </span>
                        <input className='SupportFillDivRow1Input border-2 border-[#848383]' name="carModel" />
                    </div>
                    <div className='SupportFillDivRow1'>
                        <span className='SupportFillDivRow1Span'>
                            BRAND
                        </span>
                        <input className='SupportFillDivRow1Input border-2 border-[#848383]' name="brand" />
                    </div>
                    <div className='SupportFillDivRow2'>
                        <span className='SupportFillDivRow1Span'>
                            MESSAGE
                        </span>
                        <input className='SupportFillDivRow2Input border-2 border-[#848383]' name="message" />
                    </div>
                </div>
                <div className='supportButtonDiv' onClick={handleWhatsAppClick}>
                    Whatsapp
                </div>

            </div>
        </div>
    );
}

export default Support;
