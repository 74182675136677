import React,{useState} from 'react'
import vector from '../images/HomeMob/Vector.png'
import DrawerMob2 from './DrawerMob2';
import logo from '../images/HomeMob/logo.png'

function NavbarMob2() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(prevState => !prevState);
    };


    return (
        <div className='w-full h-[83px] bg-[black] flex items-center justify-between px-5'>
            <div className='cursor-pointer' onClick={toggleDrawer}>
                <img  src={vector} alt='vector'  />
                
            </div>
            
            <div className='flex justify-center items-center'>
                  <img src={logo} alt='logo'/>
            </div>
            {isDrawerOpen && <DrawerMob2 isOpen={isDrawerOpen} />}
        </div>
    )
}

export default NavbarMob2