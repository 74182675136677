import React, { useState } from 'react';
import './Login.css';
import logo from '../images/newlogo.png';
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


    const handleSignUpOpenClick = () => {
      navigate('/signup'); 
    };


    const handleLogin = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/user`);
        console.log('Response:', response);
    
        // Check if response is JSON
        const contentType = response.headers.get('Content-Type');
        console.log('Content-Type:', contentType);
    
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          console.log('Data:', data);
    
          if (Array.isArray(data)) {
            const user = data.find((user) => user.username === username && user.password === password);
    
            if (user) {
              sessionStorage.setItem('isActive', user.isActive);
              sessionStorage.setItem('customerType', user.customerType);
              navigate('/table');
            } else {
              alert('Invalid username or password');
            }
          } else {
            console.error('Unexpected data format:', data);
            throw new Error('Unexpected data format');
          }
        } else {
          // Handle unexpected response type
          const text = await response.text();
          console.error('Unexpected response type:', text);
          throw new Error('Unexpected response type');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while logging in');
      }
    };
    
 

  return (
    <div className='loginMainDiv'>
      <div className='loginMainDivSubImg'>
        <div className='loginMainDivSub'>
          <div className='loginLogo'>
            <img style={{ width: '100%', height: '100%' }} src={logo} alt='loginlogo' />
          </div>
          <div className='loginContents'>
            <input
              placeholder='Username'
              className='loginContentsUsername'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              placeholder='Password'
              className='loginContentsUsername'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className='loginCheck'>
              <input type='checkbox' id='rememberMeCheckbox' />
              <div className='loginCheckLabel'>
                <label htmlFor='rememberMeCheckbox'>&nbsp;&nbsp;Remember me</label>
              </div>
            </div>
            <div className='loginButton' onClick={handleLogin}>
              LOGIN
            </div>
            {/* <div className='tableSignUp' onClick={handleSignUpOpenClick}>Sign Up?</div> */}
            {/* <div className='loginPassword' onClick={handleSignUpOpenClick}>Sign Up?</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
