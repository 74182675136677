import React,{useEffect,useState} from 'react'
import Navbar from './Navbar';
import './About.css'
import about from '../images/aboutus.png'
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import NavbarMob from './NavbarMob';
import FooterMob from './FooterMob';


function About() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
      // Update the screen size on resize
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };

      // Attach event listener
      window.addEventListener('resize', handleResize);

      // Clean up the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate(); // Initialize navigate
  const handleHomeOpenClick = () => {
      navigate('/'); 
    };

  return (
    <div className='mainDiv'>
    <div className='Navbar'>
    {isMobile ? <NavbarMob /> : <Navbar />}
    </div>
    <div className='aboutMainDiv'>
          <div className='aboutMainDivSub'>
            <div className='aboutMainDivSec1'>
            <div></div>
              <span className='aboutMainDivRoute'>
              <span className='ampHeadRouteSpan'  onClick={handleHomeOpenClick}>Home</span> {'>'} About
              </span>
              <span className='aboutMainDivHead'>
              About Us
              </span>
              <span className='aboutMainDivHeadContent'>
              Fazco Auo Parts Trading" is one of the company in Leading Automotive Genuine & Aftermarket spare parts.
               We supply auto spare parts for Japanese, and Korean,American cars.Our range of products includes original
                and duplicate spare parts for passenger  vehicles.Fazco Auto Parts is a Middle East based wholesaler of auto 
                spare parts for passenger vehicles.
              </span>
              <div className='aboutMainImageDiv'>
                <div className='aboutMainImageDivSub'>
                   <img alt='about' src={about} style={{height:'100%',width:'100%'}}/>
                </div>
              </div>
            </div>
            <div className='aboutMainDivSec2'>
            <div></div>
            <span className='aboutMainDivHead'>
              Vision
              </span>
              <span className='aboutMainDivHeadContent'>
              A cutting-edge online platform offering a vast array of car spare parts,
               meticulously curated for various makes and models.
               Seamlessly blending user-friendly navigation with comprehensive
               search functionalities, With a commitment to quality assurance,
                it's your ultimate destination for automotive maintenance needs.
              </span>
              <span className='aboutMainDivHead'>
              Mission
              </span>
              <div className='aboutMainDivHeadContentDiv'>
                <span className='aboutMainDivHeadContent'>1. Empower customers with access to a wide range of high-quality car spare parts,
                 ensuring they can efficiently maintain and repair their vehicles with confidence.</span>
                <span className='aboutMainDivHeadContent'>2. Foster a community-driven platform that educates and supports customers in
                 making informed decisions about their automotive needs, promoting transparency and trust. </span>
                <span className='aboutMainDivHeadContent'>3. Continuously innovate and expand our product offerings and services to meet the
                 evolving needs of car owners, striving to become the go-to destination for all things related to automotive maintenance and repair.</span>

              </div>
            </div>
           <div className='aboutMainDivSec3'>
           <span className='aboutMainDivHead'>
           What we Offer
              </span>
             <span className='aboutMainDivHeadContent'>1. World Class Brand</span> 
             <span className='aboutMainDivHeadContent'>We supply genuine and world class
              brands like Valeo, Luk, Sachs, KYB, Gabriel, Monroe, Minda, Lumax, KBX, Bosch, Talbros, Rane Gates etc</span> 
              <span className='aboutMainDivHeadContent'>2. Wide Range of Collection</span> 
             <span className='aboutMainDivHeadContent'>We offer wide range of parts like clutch sets, shock absorbers, Tie rod ends, 
             Rack ends, Control arms, Drive shafts, Brake Pads, Brake shoes, Disc Rotors, Spark plugs, filters, Lubricants, Gas springs, 
             Alternators, Starter Motors, Pistons, rings, Liners, Gaskets, Headlamps, Tail lamps, Side mirrors, Bumpers, 
             Bearings, Timing belts, Rubber parts, Cables, Flywheel, Glow plugs, Ignition coils, wiper blades, Door parts, Horns etc.</span> 
              <span className='aboutMainDivHeadContent'>3. Accuracy and Reliability</span> 
             <span className='aboutMainDivHeadContent'>We guarantee accuracy and reliability of parts</span> 

           </div>
           <div className='aboutMainDivSec4'>
           <div></div>
           <span className='aboutMainDivHead'>
           Gurantees
              </span>
              <span className='aboutMainDivHeadContent2'>
                1. We Guarantee we supply only Genuine and only new auto spareparts which we purchase from official Distributors
              </span>
              <span className='aboutMainDivHead2'>
              We are Responsible for:
              </span>
              <div className='aboutMainDivHeadContentDiv'>
                <span className='aboutMainDivHeadContent'><span className='dotHeadAbout'>.</span> Declared but not Delivered Spareparts</span>
                <span className='aboutMainDivHeadContent'><span className='dotHeadAbout'>.</span> Damaged during transportation Spareparts </span>
                <span className='aboutMainDivHeadContent'><span className='dotHeadAbout'>.</span> Defective Spareparts due to the fault of the Manufacturer </span>

              </div>
           </div>

          </div>
          
          
    </div>
    {isMobile ? <FooterMob /> : <Footer />}    </div>
  )
}

export default About