import React,{useState,useEffect} from 'react'
import './ContactUs.css'
import Navbar from './Navbar';
import redcar from '../images/redcar.png'
import contactus from '../images/CONTACT US.png'
import location from '../images/Location.png'
import viber from '../images/Viber.png'
import Footer from './Footer';
import NavbarMob from './NavbarMob';
import FooterMob from './FooterMob';


function ContactUs() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        // Update the screen size on resize
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Attach event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const handleSendEmail = () => {
        const recipientEmail = 'fazcoautoparts@gmail.com'; // Update with the recipient's email address
        const subject = 'New Message from contact us page';
        const body = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.subject}\nMessage: ${formData.message}`;
    
        // Open default email client with pre-filled email template
        window.open(`mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
    
        // Clear form data
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''  
        });      
    
        // Optionally, you can redirect or perform any other action after sending the email
    };
    
    return (
        <div className='mainDiv'>
            <div className='Navbar'>
            {isMobile ? <NavbarMob /> : <Navbar />}
            </div>
            <div className='contactImgDiv'>
                <img alt='car' src={redcar} style={{ height: '100%', width: '100%', position: 'relative', zIndex: '1' }} />
                <img className='contactusSpan' alt='text' src={contactus} />
            </div>
            <div className='contactUsContentDiv'>
                <div className='contactUsContentDivSub'>
                    <div className='contactUsContentDivSubCol1'>
                        {/* <div className='contactUsContentDivSubCol1Content1'>
                            <img src={location} alt='location' />
                            <span>WADI AL NEEL AUTO SPARE PARTS TRADING .LLC <br />
                            Authorised Distributer Fazco Auto Parts <br/>
                            UAE,Ajman.Ind,2</span>
                        </div> */}
                        <div className='contactUsContentDivSubCol1Content1'>
                            <img src={viber} alt='phone' />
                            <span> <a href='tel:00971543476417'>00971- 54 347 64 17</a></span>
                        </div>
                    </div>
                    <div className='contactUsContentDivSubCol2'>
                        <input placeholder='Name' className='contactUsContentDivSubCol2NameInput'  name='name'
                            value={formData.name}
                            onChange={handleChange}/>
                        <input placeholder='Email' className='contactUsContentDivSubCol2NameInput'  name='email'
                            value={formData.email}
                            onChange={handleChange}/>
                        <input placeholder='Subject' className='contactUsContentDivSubCol2NameInput' 
                        name='subject'
                        value={formData.subject}
                        onChange={handleChange}/>
                        <input placeholder='Message' className='contactUsContentDivSubCol2MessageInput'  name='message'
                            value={formData.message}
                            onChange={handleChange}/>
                        <div className='contactUsContentDivSubCol2ButtonDiv'>
                            <div className='contactUsContentDivSubCol2Button' onClick={handleSendEmail}>SUBMIT</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='pagesFooterDiv'>
            {isMobile ? <FooterMob /> : <Footer />}
            </div>
        </div>
    )
}

export default ContactUs