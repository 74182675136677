import React from 'react'
import './DrawerMob.css'
import arrow  from  '../images/Arrow.png'
import { useNavigate } from 'react-router-dom';

function DrawerMob({ isOpen }) {

    const navigate = useNavigate(); // Initialize navigate

  const handleDrawerOption1Click = () => {
    navigate('/'); // Navigate to '/priceupload' route when the div is clicked
  };

  const handleDrawerOption2Click = () => {
    navigate('/about'); // Navigate to '/priceupload' route when the div is clicked
  };

  const handleDrawerOption3Click = () => {
    navigate('/table'); // Navigate to '/priceupload' route when the div is clickeda
  };

  const handleDrawerOption4Click = () => {
    navigate('/start'); // Navigate to '/priceupload' route when the div is clicked
  };

  const handleDrawerOption5Click = () => {
    navigate('/contactus'); // Navigate to '/priceupload' route when the div is clicked
  };

  const handleDrawerOption6Click = () => {
    navigate('/login'); // Navigate to '/priceupload' route when the div is clicked
  };

  return (
<div className={`drawerMainDivMob ${isOpen ? 'open' : 'close'} mt-12`}>    
<div className='drawerMainOptionsMob'>
            <div className='drawerMainOptions1' onClick={handleDrawerOption1Click}>
                <div className='drawerMainOptions1Inner'>
                <div className='drawerMainOptions1Span'>Home</div>
                <div className='drawerMainOptions1Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            <div className='drawerMainOptions2' onClick={handleDrawerOption2Click}>
                <div className='drawerMainOptions2Inner'>
                <div className='drawerMainOptions2Span'>About</div>
                <div className='drawerMainOptions2Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            <div className='drawerMainOptions3' onClick={handleDrawerOption3Click}>
                <div className='drawerMainOptions3Inner'>
                <div className='drawerMainOptions3Span'>Products</div>
                <div className='drawerMainOptions3Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            <div className='drawerMainOptions4' onClick={handleDrawerOption4Click}>
                <div className='drawerMainOptions4Inner'>
                <div className='drawerMainOptions4Span'>Start</div>
                <div className='drawerMainOptions4Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            <div className='drawerMainOptions4' onClick={handleDrawerOption5Click}>
                <div className='drawerMainOptions4Inner'>
                <div className='drawerMainOptions4Span'>Contact Us</div>
                <div className='drawerMainOptions4Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            <div className='drawerMainOptions4' onClick={handleDrawerOption6Click}>
                <div className='drawerMainOptions4Inner'>
                <div className='drawerMainOptions4Span'>Login</div>
                <div className='drawerMainOptions4Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            
            
        </div>
    </div>
  )
}

export default DrawerMob