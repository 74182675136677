import React, { useState } from 'react'
import './Signup.css'
import logo from '../images/newlogo.png';

function Signup() {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


    const [formData, setFormData] = useState({
        email: '',
        address1: '',
        address2: '',
        username: '',
        customerType: '', 
        mobile: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            window.alert('Please enter a valid email address.');
            return; // Stop further execution if email format is invalid
        }
    
        try {
            const response = await fetch(`${apiBaseUrl}/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
    
            if (!response.ok) {
                throw new Error('Failed to submit form data');
            }
    
            const responseData = await response.text();
            console.log(responseData); // Log the response from the backend
    
            // Show alert for successful signup
            window.alert('Signup completed successfully.');
    
            // Reload the page to clear form fields
            window.location.reload();
        } catch (error) {
            console.error('Error submitting form data:', error);
            // Handle error, show error message to the user, etc.
        }
    };
    
    


    return (
        <div className='loginMainDiv'>
            <div className='loginMainDivSubImg'>
                <div className='signupMainDivSub'>
                    <div></div>
                    <div className='loginLogo'>
                        <img style={{ width: '60%', height: '100%' }} src={logo} alt='loginlogo' />
                    </div>
                    <div className='signupMainDivSubRow'>
                        <div className='signupMainDivSubRow1'>
                            <div></div>
                            <div className='signupMainDivSubCol'>
                                <input
                                    placeholder='Email'
                                    className='signupMainDivSubColInput'
                                    name='email'
                                    type='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='signupMainDivSubCol'>
                                <input
                                    placeholder='Address 1'
                                    className='signupMainDivSubColInput'
                                    name='address1'
                                    value={formData.address1}
                                    onChange={handleChange} />
                            </div>
                            <div className='signupMainDivSubCol'>
                                <input
                                    placeholder='User Name'
                                    className='signupMainDivSubColInput'
                                    name='username'
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='signupMainDivSubCol'>
                                <select
                                    className='signupMainDivSubColInput'
                                    name='customerType' // Make sure to set the name attribute to match the key in the state
                                    value={formData.customerType}
                                    onChange={handleChange} // Add onChange handler
                                >
                                    <option disabled value=''>select customer type</option>
                                    <option value='customer'>Customer</option>
                                    <option value='admin'>Admin</option>
                                </select>
                            </div>


                        </div>
                        <div className='signupMainDivSubRow1'>
                            <div></div>

                            <div className='signupMainDivSubCol'>
                                <input
                                    placeholder='Mobile Number'
                                    className='signupMainDivSubColInput'
                                    name='mobile'
                                    type='number'
                                    value={formData.mobile}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='signupMainDivSubCol'>
                                <input
                                    placeholder='Address 2'
                                    className='signupMainDivSubColInput'
                                    name='address2'
                                    value={formData.address2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='signupMainDivSubCol'>
                                <input
                                    placeholder='Password'
                                    className='signupMainDivSubColInput'
                                    name='password'
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='signUpButtonDiv'>
                        <div className='signUpButton' onClick={handleSubmit}>
                            Sign Up
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Signup