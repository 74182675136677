import React from 'react'
import './DrawerMob.css'
import arrow  from  '../images/Arrow.png'
import { useNavigate } from 'react-router-dom';

function DrawerMob({ isOpen }) {

    const navigate = useNavigate(); // Initialize navigate

    const customerType = sessionStorage.getItem('customerType');

  const handleDrawerOptionClick = () => {
    navigate('/'); // Navigate to '/priceupload' route when the div is clicked
  };

  const handleDrawerOption1Click = () => {
    navigate('/priceupload'); // Navigate to '/priceupload' route when the div is clicked
  };

  const handleDrawerOption2Click = () => {
    navigate('/subupload'); // Navigate to '/priceupload' route when the div is clicked
  };

  const handleDrawerOption3Click = () => {
    navigate('/priceupdate'); // Navigate to '/priceupload' route when the div is clickeda
  };

  const handleDrawerOption4Click = () => {
    navigate('/subupdate'); // Navigate to '/priceupload' route when the div is clicked
  };

  const handleCustomerListClick = () => {
    navigate('/Customers'); 
  };

  
  const handleSignupClick = () => {
    navigate('/signup'); 
  };

  return (
<div className={`drawerMainDivMob ${isOpen ? 'open' : 'close'} mt-12`}>    
<div className='drawerMainOptionsMob'>
            <div className='drawerMainOptions1' onClick={handleDrawerOptionClick}>
                <div className='drawerMainOptions1Inner'>
                <div className='drawerMainOptions1Span'>Home</div>
                <div className='drawerMainOptions1Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>

            {customerType !== 'customer' && (
            <div className='drawerMainOptions2' onClick={handleDrawerOption1Click}>
                <div className='drawerMainOptions2Inner'>
                <div className='drawerMainOptions2Span'>Price Upload</div>
                <div className='drawerMainOptions2Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            )}
            {customerType !== 'customer' && (
            <div className='drawerMainOptions3' onClick={handleDrawerOption2Click}>
                <div className='drawerMainOptions3Inner'>
                <div className='drawerMainOptions3Span'>Substitute Upload</div>
                <div className='drawerMainOptions3Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            )}
            {customerType !== 'customer' && (
            <div className='drawerMainOptions4' onClick={handleDrawerOption3Click}>
                <div className='drawerMainOptions4Inner'>
                <div className='drawerMainOptions4Span'>Price Update</div>
                <div className='drawerMainOptions4Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            )}
            {customerType !== 'customer' && (
            <div className='drawerMainOptions4' onClick={handleDrawerOption4Click}>
                <div className='drawerMainOptions4Inner'>
                <div className='drawerMainOptions4Span'>Substitute Update</div>
                <div className='drawerMainOptions4Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            )}
            {customerType !== 'customer' && (
            <div className='drawerMainOptions4' onClick={handleCustomerListClick}>
                <div className='drawerMainOptions4Inner'>
                <div className='drawerMainOptions4Span'>Customers List</div>
                <div className='drawerMainOptions4Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            )}
            {customerType !== 'customer' && (
            <div className='drawerMainOptions4' onClick={handleSignupClick}>
                <div className='drawerMainOptions4Inner'>
                <div className='drawerMainOptions4Span'>Sign Up</div>
                <div className='drawerMainOptions4Arrow'>
                <img  src={arrow} alt='arrow'/>

                </div>
                </div>
            </div>
            )}
            
            
        </div>
    </div>
  )
}

export default DrawerMob