import React,{useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom';

import Navbar from './Navbar';
import './AMP.css'
import Footer from './Footer';
import FooterMob from './FooterMob';
import NavbarMob from './NavbarMob';

function AMP() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        // Update the screen size on resize
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Attach event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const navigate = useNavigate(); // Initialize navigate
    const handleHomeOpenClick = () => {
        navigate('/'); 
      };

    return (
        <div className='mainDiv'>
            <div className='Navbar'>
            {isMobile ? <NavbarMob /> : <Navbar />}

            </div>
            <div className='ampHead'>
                <div className='ampHeadSub'>
                    <div className='ampFreediv'></div>
                    <span className='ampHeadRoute'><span className='ampHeadRouteSpan' onClick={handleHomeOpenClick}>Home</span> {'>'} After market Product</span>
                    <div className='ampTableDiv'>
                        <div className='ampTableDivRow1'>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>KOYO</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>LUCAS</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>EXEDY</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>MITSUBOSHI</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>SIKEN</span>

                            </div>
                            
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>NTN</span>

                            </div>
                        </div>
                        <div className='ampTableDivRow1'>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>NGK</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>MAHLE</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>NPR</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>MONROE</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>GABRIEL</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                                <span className='ampTableDivRow1ColSpan'>REMSA</span>

                            </div>

                        </div>
                        <div className='ampTableDivRow1'>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>HDK</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>RBI</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>TOYO</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>NACHI</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>NTN</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>GMB</span>

                            </div>
                        </div>
                        <div className='ampTableDivRow1'>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>CORTECO</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>CTR</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>DELPHI</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>DENSO</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>FEDEREL MOGUL</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>FLEET GUARD</span>

                            </div>
                        </div>
                        <div className='ampTableDivRow1'>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>FTE</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>HALDEX</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>SKF</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>TAIHO</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>ZF</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>VICTOR REINZ</span>

                            </div>
                        </div>
                        <div className='ampTableDivRow1'>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>TEIKIN</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>MANDO</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>WABCO</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>LUK</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>MANNFILTER</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>LUMAX</span>

                            </div>
                        </div>
                        <div className='ampTableDivRow1'>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>FAG</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>HANON</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>RENKENFILTERS</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>CHAMPION</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>FEREDO</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>BREMBO</span>

                            </div>
                        </div>
                        <div className='ampTableDivRow1'>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>HENGST</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>SAKURA</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>GOETZE</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>AISIN</span>

                            </div>
                            <div className='ampTableDivRow1Col'>
                            <span className='ampTableDivRow1ColSpan'>BANDO</span>

                            </div>
                            
                        </div>
                    </div>
                    <div className='ampFreediv'></div>

                </div>
                
            </div>
            {isMobile ? <FooterMob /> : <Footer />}

        </div>
    )
}

export default AMP