import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../images/HomeMob/logo.png'
import fb from '../images/HomeMob/fb.png'
import insta from '../images/HomeMob/insta.png'
import youtube from '../images/HomeMob/Youtube.png'
import x from '../images/HomeMob/x.png'


function FooterMob() {
    const navigate = useNavigate();
    const handleHomeOpenClick = () => {
        navigate('/'); 
      };
  
      const handlePrivacyOpenClick = () => {
          navigate('/privacy'); 
        };
  
        const handleTermsOpenClick = () => {
          navigate('/terms'); 
        };
    
        const handleContactOpenClick = () => {
          navigate('/contactus'); 
        };
  
        const handleAboutOpenClick = () => {
          navigate('/about'); 
        };
  
        const handleStartOpenClick = () => {
          navigate('/start'); 
        };
  
      
  
  return (
    <div className='w-full h-auto bg-[black] flex flex-col gap-8'>
          <div className='pt-8 px-3'>
              <img src={logo} alt='logo'/>
          </div>
          <div className='px-3 text-[white] text-start text-sm font-[500] font-display'>
          Fazco Auo Parts Trading" is one of the  company in Leading Automotive Genuine & Aftermarket spare parts. 
          </div>
          <div className='flex flex-row gap-3 px-3'>
              <div className='rounded-[50%] h-[35px] w-[35px] border-2 border-[white] flex justify-center items-center'>
                <img src={fb} alt='fb'/>
              </div>
              <div className='rounded-[50%] h-[35px] w-[35px] border-2 border-[white] flex justify-center items-center '>
              <img src={youtube} alt='fb'/>

              </div>
              <div className='rounded-[50%] h-[35px] w-[35px] border-2 border-[white] flex justify-center items-center'>
              <img src={insta} alt='fb'/>

              </div>
              <div className='rounded-[50%] h-[35px] w-[35px] border-2 border-[white] flex justify-center items-center'>
              <img src={x} alt='fb'/>

              </div>

          </div>
          <div className='flex flex-col px-3 gap-5 text-white justify-start items-start'>
              <span className='font-[700] text-lg font-display'>Links</span>
              <span className='font-[400] text-sm font-display' onClick={handleHomeOpenClick}>{">"} &nbsp;Home</span>
              <span className='font-[400] text-sm font-display' onClick={handleAboutOpenClick}>{">"} &nbsp;About US</span>
              <span className='font-[400] text-sm font-display' onClick={handleStartOpenClick}>{">"} &nbsp;Start</span>
              <span className='font-[400] text-sm font-display' onClick={handleContactOpenClick}>{">"} &nbsp;Contact Us</span>

          </div>
          <div className='flex flex-col px-3 gap-5 text-white justify-start items-start'>
              <span className='font-[700] text-lg font-display'>Resources</span>
              <span className='font-[400] text-sm font-display' onClick={handlePrivacyOpenClick}>{">"} &nbsp;Privacy Policy</span>
              <span className='font-[400] text-sm font-display' onClick={handleTermsOpenClick}>{">"} &nbsp;Terms and Condition</span>
         </div>
          <div className='flex flex-col px-3 gap-5 text-white justify-start items-start pb-8'>
              <span className='font-[700] text-lg font-display'>Useful Link</span>
             
              <span className='font-[400] text-sm font-display'>{">"} &nbsp;Catalogue</span>
              <span className='font-[400] text-sm font-display'>{">"} &nbsp;Brand</span>
           </div>
    </div>
  )
}

export default FooterMob